import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaPlane, FaDatabase, FaHandshake, FaGlobeAsia, FaLanguage, FaMapMarkedAlt } from 'react-icons/fa';
import '../styles/AsiaPacificMarkets.css';

// 导入图片
import image001 from '../assets/images/asia_pacific_markets/001.jpeg';
import image002 from '../assets/images/asia_pacific_markets/002.jpeg';
import image003 from '../assets/images/asia_pacific_markets/003.jpeg';
import image004 from '../assets/images/asia_pacific_markets/004.jpeg';

const AsiaPacificMarkets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 专业特点数据
  const features = [
    {
      title: "Regular Visits to Asia",
      description: "Our team conducts frequent visits to key Asian markets, strengthening relationships and staying updated on regional market trends.",
      icon: <FaPlane />
    },
    {
      title: "Extensive Asian Investor Databases",
      description: "Access to comprehensive databases of qualified Asian investors specifically interested in Australian commercial properties.",
      icon: <FaDatabase />
    },
    {
      title: "Established Professional Relationships",
      description: "Long-standing connections with key decision-makers across Asia, facilitating direct access to investment opportunities.",
      icon: <FaHandshake />
    },
    {
      title: "Strategic Marketing Partnerships",
      description: "Collaborations with leading Asian marketing platforms to ensure maximum visibility for your investments.",
      icon: <FaGlobeAsia />
    },
    {
      title: "Multilingual Capabilities",
      description: "Our diverse team offers services in multiple languages, eliminating communication barriers and enhancing client relationships.",
      icon: <FaLanguage />
    },
    {
      title: "Local Expertise with a Global Reach",
      description: "Detailed knowledge of local markets combined with international investment insights for comprehensive service delivery.",
      icon: <FaMapMarkedAlt />
    }
  ];

  return (
    <div className="asia-pacific">
      {/* 英雄区域 */}
      <div className="hero-section">
        <div className="background-container">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="map-decoration" style={{ backgroundImage: `url(${image003})` }}></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="ink-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
        </div>
        <motion.div 
          className="hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
          >Unlocking Asia's Potential, Delivering Global Success</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Asia Market</motion.h3>
          <motion.div
            variants={textReveal}
            custom={4}
            className="hero-button-container"
          >
            <motion.div 
              whileHover={{ scale: 1.05, x: 5 }}
              whileTap={{ scale: 0.98 }}
            >
              <Link to="/contact" className="cta-button">
                Explore Our Network
              </Link>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="introduction">
        <div className="intro-bg-pattern"></div>
        <div className="intro-container">
          <motion.div 
            className="intro-content"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
          >
            <h2 className="section-title">Asia Market</h2>
            <p className="section-text">
              At The One Prestige, our Asia Market team works synergistically with our Sales & Investment service lines to source and connect premier global capital for our clients. With a robust presence across Asia and an in-depth understanding of the market dynamics, we bridge the gap between Asian capital and Australian assets, delivering unparalleled value and opportunities.
            </p>
            <p className="section-text">
              Our team boasts extensive experience and deep-rooted relationships within the region. As a testament to our expertise, we have cultivated a network of over 500 professionals across key Asian markets including Mainland China, Hong Kong, Singapore, Malaysia, and beyond. This expansive footprint ensures we are well-positioned to identify and engage with the most promising investment opportunities.
            </p>
            <p className="section-text">
              Focusing on the sub $100 million market, our specialty lies in connecting Asian investors with prime Australian commercial properties, spanning sectors such as office, retail, industrial, hotels, and student accommodation. By partnering with us, you gain access to a comprehensive network of Asian investors, facilitating the strongest capital flows to your projects.
            </p>
          </motion.div>
          <motion.div 
            className="intro-image"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeIn}
          >
            <div className="intro-image-frame">
              <img src={image001} alt="Asia Pacific Market Team" />
            </div>
          </motion.div>
        </div>
      </div>

      {/* 专业优势部分 */}
      <div className="expertise">
        <div className="expertise-bg"></div>
        <div className="expertise-container">
          <motion.div 
            className="expertise-content"
            initial={{ opacity: 0, x: -30 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <h2 className="section-title">Our Asian Market Expertise</h2>
            <p className="section-text">
              While our Asia Market team is highly specialized in Sydney and Melbourne, we are equipped to assist with property inquiries across Australia. Our localized knowledge, combined with our extensive Asian network, enables us to deliver bespoke solutions that meet the unique needs of each client.
            </p>
            <p className="section-text">
              Our professionals maintain strong connections with high-net-worth individuals, family offices, and institutional investors throughout Asia, providing our clients with unparalleled access to capital sources that would otherwise remain untapped through conventional channels.
            </p>
          </motion.div>
          <motion.div 
            className="expertise-image"
            initial={{ opacity: 0, x: 30 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <div className="expertise-grid">
              <div className="expertise-item">
                <img src={image002} alt="Asian Market Overview" />
                <div className="expertise-item-overlay">
                  <h3 className="expertise-item-title">Asian Investment Landscape</h3>
                  <p className="expertise-item-text">Strategic overview of investment flows from key Asian markets</p>
                </div>
              </div>
              <div className="expertise-item">
                <img src={image003} alt="Asian City Skyline" />
                <div className="expertise-item-overlay">
                  <h3 className="expertise-item-title">Regional Presence</h3>
                  <p className="expertise-item-text">Established networks across major Asian financial centers</p>
                </div>
              </div>
              <div className="expertise-item">
                <img src={image004} alt="Business Meeting" />
                <div className="expertise-item-overlay">
                  <h3 className="expertise-item-title">Professional Connections</h3>
                  <p className="expertise-item-text">Building lasting relationships with key stakeholders</p>
                </div>
              </div>
              <div className="expertise-item">
                <img src={image001} alt="Investment Conference" />
                <div className="expertise-item-overlay">
                  <h3 className="expertise-item-title">Market Intelligence</h3>
                  <p className="expertise-item-text">Detailed insights into market trends and investment opportunities</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 特色和优势部分 */}
      <div className="features">
        <div className="features-title">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Our Expertise is Backed By
          </motion.h2>
        </div>
        <div className="features-container">
          <div className="features-grid">
            {features.map((feature, index) => (
              <motion.div 
                key={index}
                className="feature-item"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <div className="feature-icon">
                  {feature.icon}
                </div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-text">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* 全球资本部分 */}
      <div className="global-capital">
        <div className="global-bg" style={{ backgroundImage: `url(${image004})` }}></div>
        <div className="global-overlay"></div>
        <div className="global-container">
          <motion.div 
            className="global-content"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <h2 className="section-title">Unlocking Global Capital</h2>
            <p className="section-text">
              Partnering with The One Prestige means tapping into an extensive Asian network, gaining unparalleled access to the strongest capital sources globally. Our proven track record and strategic approach ensure that your property investments are well-positioned to attract and secure high-quality capital, driving growth and maximizing returns.
            </p>
            <div className="global-stats">
              <motion.div 
                className="stat-item"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1, duration: 0.5 }}
              >
                <div className="stat-number">500+</div>
                <div className="stat-text">Professional Connections Across Asia</div>
              </motion.div>
              <motion.div 
                className="stat-item"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <div className="stat-number">8+</div>
                <div className="stat-text">Languages Supported</div>
              </motion.div>
              <motion.div 
                className="stat-item"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                <div className="stat-number">10+</div>
                <div className="stat-text">Years of Regional Experience</div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 联系部分 */}
      <div className="contact">
        <div className="contact-container">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Get in touch
          </motion.h2>
          <motion.p 
            className="contact-text"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1, duration: 0.6 }}
          >
            For more information on how our Asia Market team can assist you, please contact our expertise team.
          </motion.p>
          <Link to="/contact">
            <motion.div 
              className="contact-button"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Contact us
            </motion.div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AsiaPacificMarkets; 