import React, { useEffect, useRef } from 'react';
import '../styles/ResidentialProjectMarketing.css';
import { motion } from 'framer-motion';

// 导入图片
import image001 from '../assets/images/residential_project_marketing/001.jpeg';
import image002 from '../assets/images/residential_project_marketing/002.jpeg';
import image003 from '../assets/images/residential_project_marketing/003.jpeg';
import image004 from '../assets/images/residential_project_marketing/004.jpeg';
import image005 from '../assets/images/residential_project_marketing/005.jpeg';
import image006 from '../assets/images/residential_project_marketing/006.jpeg';
import image007 from '../assets/images/residential_project_marketing/007.jpeg';
import image008 from '../assets/images/residential_project_marketing/008.jpeg';
import image009 from '../assets/images/residential_project_marketing/009.jpeg';
import image010 from '../assets/images/residential_project_marketing/010.jpeg';
import image011 from '../assets/images/residential_project_marketing/011.jpeg';
import image012 from '../assets/images/residential_project_marketing/012.jpeg';

const ResidentialProjectMarketing = () => {
  const canvasRef = useRef(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    // 创建粒子动画背景
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];
    
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initParticles();
    };
    
    const initParticles = () => {
      particles = [];
      const particleCount = Math.min(Math.floor(canvas.width * canvas.height / 9000), 150);
      
      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 3 + 0.5,
          speedX: Math.random() * 0.3 - 0.15,
          speedY: Math.random() * 0.3 - 0.15,
          opacity: Math.random() * 0.5 + 0.1,
          color: Math.random() > 0.8 ? '#CFB483' : '#ffffff'
        });
      }
    };
    
    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // 绘制基础渐变背景
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      gradient.addColorStop(0, '#030e24');
      gradient.addColorStop(0.3, '#0a1c3d');
      gradient.addColorStop(0.6, '#0e2042');
      gradient.addColorStop(1, '#030c1d');
      
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // 绘制金色弧线
      drawGoldenArcs(ctx, canvas.width, canvas.height);
      
      // 绘制粒子
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = particle.color === '#CFB483' 
          ? `rgba(207, 180, 131, ${particle.opacity})` 
          : `rgba(255, 255, 255, ${particle.opacity})`;
        ctx.fill();
        
        // 更新粒子位置
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        
        // 边界检查
        if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;
      });
      
      // 绘制连接线
      drawConnections(ctx, particles);
      
      animationFrameId = requestAnimationFrame(drawParticles);
    };
    
    const drawGoldenArcs = (ctx, width, height) => {
      // 大弧
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(207, 180, 131, 0.15)';
      ctx.lineWidth = 1;
      ctx.arc(width * 0.8, height * 0.2, height * 0.6, 0, Math.PI * 1.2);
      ctx.stroke();
      
      // 中弧
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(207, 180, 131, 0.1)';
      ctx.lineWidth = 1.5;
      ctx.arc(width * 0.3, height * 0.8, height * 0.5, Math.PI, Math.PI * 1.8);
      ctx.stroke();
      
      // 小弧
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(207, 180, 131, 0.2)';
      ctx.lineWidth = 0.5;
      ctx.arc(width * 0.5, height * 0.5, height * 0.25, 0, Math.PI * 2);
      ctx.stroke();
      
      // 装饰线
      for (let i = 0; i < 3; i++) {
        const startX = Math.random() * width * 0.3;
        const startY = Math.random() * height;
        const endX = startX + Math.random() * width * 0.7;
        const endY = Math.random() * height;
        
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = 'rgba(207, 180, 131, 0.05)';
        ctx.lineWidth = Math.random() * 0.5 + 0.2;
        ctx.stroke();
      }
    };
    
    const drawConnections = (ctx, particles) => {
      const maxDistance = 150;
      
      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            const opacity = (1 - distance / maxDistance) * 0.1;
            ctx.strokeStyle = particles[i].color === '#CFB483' || particles[j].color === '#CFB483' 
              ? `rgba(207, 180, 131, ${opacity})` 
              : `rgba(255, 255, 255, ${opacity})`;
            ctx.lineWidth = 0.5;
            ctx.stroke();
          }
        }
      }
    };
    
    resizeCanvas();
    drawParticles();
    
    window.addEventListener('resize', resizeCanvas);
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const textReveal = {
    hidden: { opacity: 0, y: 30 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.7,
        delay: custom * 0.2,
        ease: [0.42, 0, 0.58, 1]
      }
    })
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: (custom) => ({
      opacity: 1,
      transition: { 
        duration: 0.8,
        delay: custom * 0.2
      }
    })
  };

  const services = [
    {
      id: 1,
      title: "MARKET RESEARCH",
      description: "Good market research in the context of a residential property project marketing business involves gathering and analyzing data to understand the market conditions, buyer preferences, and competitive landscape.",
      image: image001,
      icon: "fas fa-chart-line"
    },
    {
      id: 2,
      title: "CAMPAIGN PLANNING",
      description: "Effective campaign planning ensures that marketing efforts are coordinated, focused, and aligned with the project's overall goals, maximizing the impact and reach of the campaign.",
      image: image002,
      icon: "fas fa-bullseye"
    },
    {
      id: 3,
      title: "PRICING STRATEGY",
      description: "An effective pricing strategy ensures that the property is attractive to buyers, competitive in the market, and profitable for the developer.",
      image: image003,
      icon: "fas fa-tags"
    },
    {
      id: 4,
      title: "BRAND DEVELOPMENT",
      description: "We are crafting a clear brand message that highlights the unique selling points and benefits of the property, designing a memorable logo and visual elements, and establishing a consistent tone and style across all marketing materials.",
      image: image004,
      icon: "fas fa-paint-brush"
    },
    {
      id: 5,
      title: "AUTOMATED REPORTING",
      description: "Automated reporting ensures real-time data collection and analysis, provides visual dashboards and performance summaries, and delivers actionable insights to help make informed decisions. By streamlining the reporting process, it allows marketers to focus on strategy and optimization rather than manual data compilation.",
      image: image005,
      icon: "fas fa-chart-bar"
    },
    {
      id: 6,
      title: "SALES STRATEGY DEVELOPMENT",
      description: "Our strategy development integrates marketing efforts with sales initiatives, leverage data-driven insights to refine approaches, and establish a robust follow-up process to convert leads into sales. By aligning sales tactics with market conditions and buyer motivations, our strategy aims to maximize sales performance and achieve project goals.",
      image: image006,
      icon: "fas fa-handshake"
    },
    {
      id: 7,
      title: "AGENT NETWORK MANAGEMENT",
      description: "Our team manages all participating agents by effectively recruiting, training, and coordinating all agents to optimize the promotion and sale of the property. This includes providing agents with comprehensive product knowledge, marketing materials, and incentives to ensure they are well-equipped and motivated to represent the project.",
      image: image007,
      icon: "fas fa-users"
    },
    {
      id: 8,
      title: "LEADING SALES AGENT",
      description: "A good leading sales agent in residential property project marketing is a highly skilled professional who excels in driving property sales and building strong client relationships. They possess in-depth knowledge of the project and the local market, effectively communicate its benefits, and provide personalized service to meet clients' needs.",
      image: image008,
      icon: "fas fa-star"
    },
    {
      id: 9,
      title: "CAMPAIGN MANAGEMENT",
      description: "Good campaign management in residential property project marketing involves overseeing and coordinating all aspects of a marketing campaign to ensure its success. This includes planning and executing strategies, managing budgets, and tracking performance metrics.",
      image: image009,
      icon: "fas fa-tasks"
    },
    {
      id: 10,
      title: "AFTER-SALES COMMUNICATION",
      description: "Effective after-sales communication helps ensure a smooth transition for the buyer, reinforces their positive experience, and fosters long-term satisfaction and loyalty. By staying engaged and attentive, it can also lead to referrals and repeat business.",
      image: image010,
      icon: "fas fa-comments"
    },
    {
      id: 11,
      title: "SETTLEMENT MANAGEMENT",
      description: "Effective settlement management ensures that all contractual obligations are met, the buyer's and seller's expectations are fulfilled, and the transition to ownership is seamless, ultimately contributing to a positive overall experience for all parties involved.",
      image: image011,
      icon: "fas fa-file-contract"
    },
    {
      id: 12,
      title: "STAFFING YOUR DISPLAY SUITE",
      description: "Good staffing for your display suite in residential property project marketing involves selecting and training a team of knowledgeable and professional staff to effectively showcase the property and engage potential buyers.",
      image: image012,
      icon: "fas fa-user-tie"
    }
  ];

  return (
    <div className="project-marketing">
      {/* 全新设计的头部区域 */}
      <div className="project-hero-section">
        <canvas ref={canvasRef} className="background-canvas"></canvas>
        
        <div className="prestige-decoration">
          <div className="golden-separator-top"></div>
          <div className="golden-building-silhouette"></div>
          <div className="golden-separator-bottom"></div>
        </div>
        
        <motion.div 
          className="project-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.div className="prestige-logo" variants={textReveal} custom={0.5}>
            <div className="logo-diamond"></div>
          </motion.div>
          <motion.h1 variants={textReveal} custom={1}>THE ONE PRESTIGE</motion.h1>
          <motion.h2 variants={textReveal} custom={2}>Prestige in Finance, Excellence in Service</motion.h2>
          <motion.p variants={textReveal} custom={3}>
            Crafting Financial Success with Prestige and Precision
          </motion.p>
          <motion.button 
            className="project-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            Get in Touch
          </motion.button>
        </motion.div>
        
        <div className="hero-floating-elements">
          <div className="floating-element element-1"></div>
          <div className="floating-element element-2"></div>
          <div className="floating-element element-3"></div>
          <div className="golden-accent accent-1"></div>
          <div className="golden-accent accent-2"></div>
        </div>
      </div>

      {/* 专家介绍部分 */}
      <motion.div 
        className="project-introduction"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <div className="intro-content">
          <h2>PROJECT MARKETING SPECIALISTS</h2>
          <div className="gold-separator"></div>
          <p>Selling with us means exposing your property to as many buyers as possible.
            At The One Prestige, we understand that your financial well-being is a cornerstone of a fulfilling life. 
            As a leading provider of personalized financial services, our mission is to empower you with tailored 
            solutions that align with your unique goals and aspirations.</p>
          
          <p>Our team of seasoned experts brings a wealth of knowledge and a commitment to excellence, ensuring that 
            every strategy we develop is crafted with precision and care. Whether you're planning for retirement, 
            managing investments, or seeking advice on estate planning, we offer a comprehensive suite of services 
            designed to optimize your financial health.</p>
        </div>
      </motion.div>

      {/* 精美引言区 */}
      <div className="project-quote-section">
        <div className="quote-background">
          <img src={image007} alt="Luxury Interior" />
          <div className="quote-overlay"></div>
        </div>
        <motion.div 
          className="quote-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="quote-icon">
            <i className="fas fa-quote-left"></i>
          </div>
          <h3>"Transforming Spaces, Captivating Buyers."</h3>
          <p>We specialize in turning residential projects into highly sought-after properties. Our expert team combines market insights, strategic marketing, and creative solutions to showcase your residential developments effectively. From branding and digital campaigns to on-site promotions, we ensure your projects attract the right buyers and stand out in a competitive market.</p>
          <div className="quote-signature">
            <span>The One Prestige</span>
          </div>
        </motion.div>
      </div>

      {/* 服务网格 */}
      <div className="project-services-container">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          Our Comprehensive Services
        </motion.h2>
        <div className="project-services-grid">
          {services.map((service, index) => (
            <motion.div 
              key={service.id}
              className="project-service-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 % 0.4 }}
            >
              <div className="service-image-container">
                <span className="service-number">{String(service.id).padStart(2, '0')}</span>
                <img src={service.image} alt={service.title} />
                <div className="image-overlay"></div>
              </div>
              <div className="service-content">
                <div className="service-top">
                  <div>
                    <h3>{service.title}</h3>
                  </div>
                  <div className="service-icon">
                    <i className={service.icon}></i>
                  </div>
                </div>
                <div className="service-separator"></div>
                <p>{service.description}</p>
                <div className="service-read-more">
                  Learn More <i className="fas fa-arrow-right"></i>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 联系我们部分 */}
      <div className="project-contact-section">
        <div className="contact-background">
          <img src={image009} alt="Luxury Property" />
          <div className="contact-overlay"></div>
        </div>
        <motion.div 
          className="project-contact-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <motion.h2 variants={fadeIn} custom={1}>Welcome to THE ONE PRESTIGE</motion.h2>
          <motion.div className="contact-separator" variants={fadeIn} custom={2}></motion.div>
          <motion.p variants={fadeIn} custom={3}>
            Contact us to learn more about our services and how we can enhance your living experience.
          </motion.p>
          <motion.div className="project-cta-buttons" variants={fadeIn} custom={4}>
            <button className="project-primary-btn">Contact Us</button>
            <button className="project-secondary-btn">Learn More</button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ResidentialProjectMarketing; 