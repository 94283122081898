import React, { useEffect } from 'react';
import '../styles/CommercialLease.css';
import image001 from '../assets/images/commercial_lease/001.jpeg';
import image002 from '../assets/images/commercial_lease/002.jpeg';
import image003 from '../assets/images/commercial_lease/003.jpeg';
import image004 from '../assets/images/commercial_lease/004.jpeg';
import { motion } from 'framer-motion';

const CommercialLease = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.6,
        delay: custom * 0.15,
        ease: [0.25, 0.1, 0.25, 1]
      }
    })
  };

  const sectionReveal = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.7,
        ease: [0.25, 0.1, 0.25, 1]
      }
    }
  };

  // 服务类别数据
  const leasingServices = [
    {
      id: 1,
      title: "Office Leasing",
      description: "Our office leasing team is comprised of seasoned professionals with an in-depth knowledge of the Sydney CBD commercial property market. Our experts have a deep understanding of what tenants want in a modern workplace, as well as how the surrounding precinct can further enhance this experience. We are committed to delivering tailored solutions that meet the unique needs of our clients, ensuring seamless transactions and long-term partnerships.",
      icon: "fas fa-building",
      image: image002
    },
    {
      id: 2,
      title: "Retail Leasing",
      description: "Our Commercial Retail Leasing team boasts a deep understanding of the Sydney CBD and suburban area retail landscape. With a keen eye for trends and a proven ability to identify prime locations, our experts excel at matching retailers with ideal spaces. Our team's knowledge of lease structures, tenant mix optimisation, and market valuation enables us to deliver exceptional results for both landlords and tenants.",
      icon: "fas fa-store",
      image: image003
    },
    {
      id: 3,
      title: "Industrial Leasing",
      description: "Our Commercial Industrial Leasing team brings a wealth of experience to the Sydney market. With a deep understanding of industrial property requirements, we specialize in matching businesses with suitable warehouses, distribution centers, and manufacturing facilities. Strategically engaging with our clients we leverage our full suite of industrial services to offer industry leading advice and deliver enterprising property solutions. Our team's expertise in lease negotiations, property valuation, and market trends ensures optimal outcomes for both landlords and tenants.",
      icon: "fas fa-industry",
      image: image004
    }
  ];

  // 核心价值数据
  const coreValues = [
    {
      title: "The Bold and Innovative",
      description: "We're shaking up the commercial leasing industry with our fresh approach. Our expert team is dedicated to delivering seamless, innovative solutions tailored to your unique needs. From finding the perfect space to negotiating the best deal, we've got you covered.",
      icon: "fas fa-lightbulb"
    },
    {
      title: "The Trusted Advisor",
      description: "With years of experience in the Sydney commercial real estate market, we've established ourselves as trusted advisors. Our clients rely on our expertise, integrity, and commitment to excellence to guide them through complex leasing decisions.",
      icon: "fas fa-handshake"
    },
    {
      title: "The Customer-Centric",
      description: "At The One Prestige, we put our clients at the heart of everything we do. We listen carefully to your needs, understand your business objectives, and work tirelessly to find solutions that align with your strategic goals and operational requirements.",
      icon: "fas fa-users"
    }
  ];

  return (
    <div className="commercial-lease">
      {/* 高端视觉头部区域 */}
      <div className="commercial-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="commercial-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Leasing Made Easy</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Your Trusted Leasing Partner</motion.h3>
          <motion.button 
            className="commercial-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 精致介绍部分 */}
      <motion.div 
        className="commercial-introduction"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionReveal}
      >
        <div className="subtle-pattern"></div>
        <div className="intro-content">
          <h2>Sydney's Premier Commercial Leasing Specialists</h2>
          <div className="gold-separator"></div>
          <p>The One Prestige is a commercial leasing specialist based in the heart of Sydney CBD. We offer comprehensive property management services for landlords and efficient space solutions for tenants. Our deep understanding of the Sydney market enables us to deliver tailored leasing strategies and exceptional customer service.</p>
          <p className="highlighted-text">"A successful commercial leasing service should focus on delivering value to both landlords and tenants, building strong relationships with both parties and achieve long-term success."</p>
        </div>
        <div className="intro-image">
          <img src={image001} alt="Commercial Leasing" />
        </div>
      </motion.div>

      {/* 核心价值部分 */}
      <div className="core-values-section">
        <div className="values-bg-pattern"></div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Core Values
        </motion.h2>
        <div className="values-container">
          {coreValues.map((value, index) => (
            <motion.div 
              key={index}
              className="value-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <div className="value-icon">
                <i className={value.icon}></i>
              </div>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 视觉分隔部分 */}
      <div className="visual-divider">
        <div className="parallax-image" style={{ backgroundImage: `url(${image003})` }}>
          <div className="overlay"></div>
          <div className="content-container">
            <motion.div
              className="divider-content"
              initial={{ opacity: 0, y: 20, x: -20 }}
              whileInView={{ opacity: 1, y: 0, x: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.8, 
                ease: "easeOut",
                delay: 0.2
              }}
            >
              <motion.h2
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                In THE ONE, we have a dedicated team specializing in project marketing specifically and exclusively for developers.
              </motion.h2>
              <motion.div 
                className="gold-separator"
                initial={{ width: 0 }}
                whileInView={{ width: "80px" }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.8 }}
              ></motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1 }}
              >
                Projects that cater for boutique and large-scale projects is where our passion lays. Working together we inspire clients with our infectious energy and collaborative working style that emphasizes teamwork and a passion for our clients.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* 服务类别部分 */}
      <div className="leasing-services-section">
        <div className="services-bg-pattern"></div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Leasing Services
        </motion.h2>
        
        <div className="services-container">
          {leasingServices.map((service, index) => (
            <motion.div 
              key={service.id}
              className="service-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <div className={`service-content ${index % 2 !== 0 ? 'reverse' : ''}`}>
                <div className="service-text">
                  <div className="service-header">
                    <div className="service-icon">
                      <i className={service.icon}></i>
                    </div>
                    <h3>{service.title}</h3>
                  </div>
                  <div className="service-separator"></div>
                  <p>{service.description}</p>
                </div>
                <div className="service-image">
                  <img src={service.image} alt={service.title} />
                  <div className="image-number">{service.id}</div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 联系我们部分 */}
      <div className="contact-section">
        <div className="contact-overlay"></div>
        <div className="contact-pattern"></div>
        <div className="contact-content">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Welcome to THE ONE PRESTIGE
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1, duration: 0.6 }}
          >
            Contact us to learn more about our services and how we can enhance your commercial leasing experience.
          </motion.p>
          <motion.button
            className="contact-button"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.6 }}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
          >
            Contact us
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default CommercialLease; 