import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Team.css';
import image001 from '../assets/images/team/001.jpeg';
import image002 from '../assets/images/team/002.jpeg';
import image003 from '../assets/images/team/003.jpeg';
import image004 from '../assets/images/team/004.jpeg';
import image005 from '../assets/images/team/005.jpeg';

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="team-page">
      <div className="team-header" data-aos="fade-up">
        <h1>Our Team</h1>
        <div className="header-content">
          <p className="header-subtitle">The powerhouse behind your real estate dreams</p>
          <p className="header-description">At The One Prestige family, our people are our greatest asset. We pride ourselves on having a team of dedicated professionals who are not only experts in their respective fields but also passionate about delivering exceptional service to our clients.</p>
        </div>
      </div>

      <div className="team-vision" data-aos="fade-up">
        <h2>OUR PEOPLE ARE HIGHLY EXPERIENCED, FOCUSED AND DRIVEN BY RESULTS.</h2>
        <p>Our team's diverse backgrounds and extensive industry experience enable us to offer a unique blend of local knowledge and global perspective, ensuring we meet and exceed our clients' expectations.</p>
      </div>

      <div className="team-gallery">
        <div className="gallery-grid">
          <div className="team-member" data-aos="fade-up" data-aos-duration="800">
            <div className="member-image">
              <img src={image001} alt="Strategic Excellence" />
              <div className="member-overlay">
                <h3>Strategic Excellence</h3>
                <p>Our strategic approach combines deep market analysis with innovative solutions to deliver exceptional results for our clients.</p>
              </div>
            </div>
          </div>
          <div className="team-member" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100">
            <div className="member-image">
              <img src={image002} alt="Client-Centric Focus" />
              <div className="member-overlay">
                <h3>Client-Centric Focus</h3>
                <p>We prioritize understanding your unique needs and goals, ensuring personalized solutions that exceed expectations.</p>
              </div>
            </div>
          </div>
          <div className="team-member" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
            <div className="member-image">
              <img src={image003} alt="Collaborative Spirit" />
              <div className="member-overlay">
                <h3>Collaborative Spirit</h3>
                <p>Our team's synergy and expertise create powerful solutions that drive success in every real estate venture.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team-expertise" id="meet-our-team">
        <div className="expertise-content">
          <div className="text-section" data-aos="fade-right" data-aos-offset="300">
            <h2>Meet Our Team</h2>
            <p>With a wealth of experience and a deep understanding of the local market, our agents are equipped with the knowledge and resources to guide you through every step of the buying and selling process. From initial consultation to closing the deal, our team works tirelessly to ensure that your goals are not only met but exceeded.</p>
          </div>
          <div className="image-section" data-aos="fade-left" data-aos-offset="300">
            <img src={image004} alt="Team Meeting" className="floating-image" />
          </div>
        </div>
      </div>

      <div className="team-values">
        <div className="values-content">
          <div className="image-section" data-aos="fade-right">
            <img src={image005} alt="Team Collaboration" className="floating-image" />
          </div>
          <div className="text-section" data-aos="fade-left">
            <h2>Our Values</h2>
            <p>What sets our team apart is our unwavering dedication to client satisfaction. We take the time to truly understand your unique needs and preferences, tailoring our approach to achieve the best possible outcome for you. Whether you're a first-time buyer, seasoned investor, or looking to sell your property, you can trust that our team will go above and beyond to deliver results.</p>
          </div>
        </div>
      </div>

      <div className="contact-section-wrapper">
        <img src={image001} className="contact-bg-image" alt="Background" />
        <div className="contact-section-overlay">
          <div className="contact-content">
            <h2>Get in touch</h2>
            <p>Contact us to connect with one of our agents and embark on your real estate journey with confidence.</p>
            <Link to="/contact" className="contact-button">Contact us</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team; 