import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSearch, FaChartLine, FaHandshake, FaCalculator, FaHeadset, FaGlobeAmericas, FaUserTie } from 'react-icons/fa';
import '../styles/TenantRepresentative.css';

// 导入图片
import heroBackground from '../assets/images/teant_representative/001.jpeg';
import image001 from '../assets/images/teant_representative/001.jpeg';
import image002 from '../assets/images/teant_representative/002.jpeg';
import image003 from '../assets/images/teant_representative/003.jpeg';
import image004 from '../assets/images/teant_representative/004.jpeg';
import image005 from '../assets/images/teant_representative/005.jpeg';
import image006 from '../assets/images/teant_representative/006.jpeg';
import image007 from '../assets/images/teant_representative/007.jpeg';
import image008 from '../assets/images/teant_representative/008.jpeg';

const TenantRepresentative = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  // const fadeIn = {
  //   hidden: { opacity: 0, y: 20 },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       duration: 0.8,
  //       ease: "easeOut"
  //     }
  //   }
  // };

  // 从左侧进入动画
  const fadeInLeft = {
    hidden: { opacity: 0, x: -30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 从右侧进入动画
  const fadeInRight = {
    hidden: { opacity: 0, x: 30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 服务项目数据
  const services = [
    {
      title: "Comprehensive Search",
      description: "We start by helping clients determine precisely what they want and need in a commercial space. We then conduct a tailored search to find the best options, sourcing proposals and refining shortlists to identify the superior choice.",
      icon: <FaSearch />,
      image: image002
    },
    {
      title: "Market Analysis",
      description: "Our team provides in-depth market analysis to give clients a clear understanding of local and international property markets. This includes insights into market trends, property values, and emerging opportunities.",
      icon: <FaChartLine />,
      image: image003
    },
    {
      title: "Negotiation and Deal Structuring",
      description: "We negotiate the most advantageous deals on behalf of our clients, ensuring that lease agreements are structured to provide optimal terms and conditions. Our negotiation strategies are designed to secure the best possible outcomes.",
      icon: <FaHandshake />,
      image: image004
    },
    {
      title: "Financial Analysis",
      description: "We offer superior financial analysis to help clients understand the economic implications of their property choices. This includes evaluating lease terms, conducting cost-benefit analyses, and identifying opportunities for cost savings.",
      icon: <FaCalculator />,
      image: image005
    },
    {
      title: "Ongoing Support",
      description: "Our service is marked by regular communication and ongoing support. We assist clients throughout the entire leasing process, from initial search to lease signing and beyond, ensuring a smooth and successful transaction.",
      icon: <FaHeadset />,
      image: image006
    },
    {
      title: "Global Reach",
      description: "Thanks to our extensive network and strong relationships with property owners and brokers, we are well-equipped to handle both local and international transactions. Our global reach ensures that we can provide superior service no matter where our clients are looking for space.",
      icon: <FaGlobeAmericas />,
      image: image007
    },
    {
      title: "Discreet and Professional Service",
      description: "In every instance, our service is discreet, professional, and characterized by regular communication. We prioritize our clients' best interests, maintaining confidentiality and delivering results that exceed expectations.",
      icon: <FaUserTie />,
      image: image008
    }
  ];

  // 专业领域数据
  // const expertiseAreas = [
  //   {
  //     title: "Office Space",
  //     description: "From small startups to large corporations, we help clients find the perfect office space that meets their specific requirements and budget."
  //   },
  //   {
  //     title: "Retail Properties",
  //     description: "We assist retailers in finding prime locations that attract maximum foot traffic and visibility for their businesses."
  //   },
  //   {
  //     title: "Industrial and Warehousing",
  //     description: "Our expertise extends to industrial spaces and warehouses, where we help clients optimize logistics and operations."
  //   }
  // ];

  return (
    <div className="tenant-representative">
      {/* 英雄区域 */}
      <div className="hero-section">
        <div className="background-container" style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="blueprint-lines">
            <div className="blueprint-line blueprint-line-1"></div>
            <div className="blueprint-line blueprint-line-2"></div>
            <div className="blueprint-line blueprint-line-3"></div>
            <div className="blueprint-line blueprint-line-4"></div>
          </div>
          <div className="light-effect"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
        </div>
        <motion.div 
          className="hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.01, transition: { duration: 0.3 } }}
          >Your Needs, Our Priority</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Tenant Representative</motion.h3>
          <motion.div
            variants={textReveal}
            custom={4}
            className="hero-button-container"
          >
            <motion.div 
              whileHover={{ scale: 1.05, x: 5 }}
              whileTap={{ scale: 0.98 }}
            >
              <Link to="/contact" className="cta-button">
                Discuss Your Requirements
              </Link>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="intro-section">
        <div className="intro-bg-pattern"></div>
        <div className="intro-container">
          <motion.div 
            className="intro-content"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInLeft}
          >
            <h2 className="section-title">Tenant Representative</h2>
            
            <motion.div 
              className="intro-quote"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <p>"Being a tenant can present both challenges and opportunities. At The One Prestige, we work with occupiers to minimize the former and maximize the latter, ensuring that our clients find the perfect commercial property space to meet their needs."</p>
            </motion.div>
            
            <motion.div 
              className="highlight-text"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              Our dedicated professionals apply a profound understanding of local markets, national law, and international prospects to assist clients seeking commercial property space of all types and sizes.
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5, duration: 0.6 }}
            >
              <h3 className="subsection-title">How We Work</h3>
              <p className="section-text">
                Our approach goes beyond mere transactions; we focus on understanding precisely what our clients want and need, then tailor our search accordingly. By sourcing proposals, refining shortlists, and negotiating the most advantageous deals, we always represent the best interests of our tenant clients.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6, duration: 0.6 }}
            >
              <h3 className="subsection-title">Areas of Expertise</h3>
              <p className="section-text">
                Whether our clients are looking for office space, retail shopfronts, or warehouses, we provide comprehensive tenant representation services tailored to both small local instructions and large international transactions. Our expertise spans various property types and market segments, ensuring that we can meet the unique needs of each client. Our team excels in financial analysis, negotiation strategies, and the structuring of commercial lease agreements, leading to significantly better economics for our clients.
              </p>
            </motion.div>
          </motion.div>
          <motion.div 
            className="intro-image"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInRight}
          >
            <div className="intro-image-frame">
              <img src={image001} alt="Tenant Representative Team" />
            </div>
            
            <motion.div 
              className="intro-side-info"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.7, duration: 0.6 }}
            >
              <div className="info-item">
                <div className="info-icon"><FaHandshake /></div>
                <div className="info-text">Professional team to find the ideal space for you</div>
              </div>
              <div className="info-item">
                <div className="info-icon"><FaChartLine /></div>
                <div className="info-text">Customized commercial solutions</div>
              </div>
              <div className="info-item">
                <div className="info-icon"><FaUserTie /></div>
                <div className="info-text">Optimize lease terms and economic benefits</div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* 专业领域部分 */}
      <div className="expertise-section">
        <div className="expertise-bg"></div>
        <div className="expertise-container">
          <div className="expertise-header">
            <motion.h2 
              className="section-title"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Our Areas of Expertise
            </motion.h2>
            <motion.p 
              className="expertise-description section-text"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              We provide comprehensive tenant representation services across various property types and market segments.
            </motion.p>
          </div>

          <motion.div 
            className="expertise-showcase"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            {/* 标题行 */}
            <motion.div 
              className="expertise-title-row"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              <div className="expertise-title-line"></div>
              <motion.h3 
                className="expertise-main-title"
                initial={{ y: 30, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5, duration: 0.7, type: "spring", stiffness: 100 }}
              >
                Tailored Tenant Solutions
              </motion.h3>
            </motion.div>
            
            {/* 专业卡片区域 */}
            <div className="expertise-cards">
              {/* 办公空间 */}
              <motion.div 
                className="expertise-card"
                initial={{ opacity: 0, y: 50, rotateY: -15 }}
                whileInView={{ opacity: 1, y: 0, rotateY: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.6, duration: 0.7 }}
                whileHover={{ scale: 1.02 }}
              >
                <div className="expertise-card-image office-image"></div>
                <div className="expertise-card-icon">
                  <FaSearch />
                </div>
                <div className="expertise-card-content">
                  <h3 className="expertise-card-title">Office Space</h3>
                  <p className="expertise-card-text">
                    From small startups to large corporations, we help clients find the perfect office space that meets their specific requirements and budget.
                  </p>
                  <Link to="/contact" className="expertise-card-button">
                    Explore More
                  </Link>
                </div>
              </motion.div>
              
              {/* 零售物业 */}
              <motion.div 
                className="expertise-card"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.7, duration: 0.7 }}
                whileHover={{ scale: 1.02 }}
              >
                <div className="expertise-card-image retail-image"></div>
                <div className="expertise-card-icon">
                  <FaChartLine />
                </div>
                <div className="expertise-card-content">
                  <h3 className="expertise-card-title">Retail Properties</h3>
                  <p className="expertise-card-text">
                    We assist retailers in finding prime locations that attract maximum foot traffic and visibility for their businesses.
                  </p>
                  <Link to="/contact" className="expertise-card-button">
                    Explore More
                  </Link>
                </div>
              </motion.div>
              
              {/* 工业和仓储 */}
              <motion.div 
                className="expertise-card"
                initial={{ opacity: 0, y: 50, rotateY: 15 }}
                whileInView={{ opacity: 1, y: 0, rotateY: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.8, duration: 0.7 }}
                whileHover={{ scale: 1.02 }}
              >
                <div className="expertise-card-image industrial-image"></div>
                <div className="expertise-card-icon">
                  <FaHandshake />
                </div>
                <div className="expertise-card-content">
                  <h3 className="expertise-card-title">Industrial and Warehousing</h3>
                  <p className="expertise-card-text">
                    Our expertise extends to industrial spaces and warehouses, where we help clients optimize logistics and operations.
                  </p>
                  <Link to="/contact" className="expertise-card-button">
                    Explore More
                  </Link>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 相关服务部分 */}
      <div className="services-section">
        <div className="services-bg-pattern"></div>
        <div className="services-container">
          <div className="services-title">
            <motion.h2 
              className="section-title"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Related Services
            </motion.h2>
          </div>

          <div className="services-grid">
            {services.slice(0, 3).map((service, index) => (
              <motion.div 
                key={index}
                className="service-item"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <div className="service-content">
                  <div className="service-icon">
                    {service.icon}
                  </div>
                  <h3 className="service-title">{service.title}</h3>
                  <p className="service-text">{service.description}</p>
                </div>
              </motion.div>
            ))}

            {/* 创新: 两列布局的服务项 */}
            <motion.div 
              className="dual-service-item"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <div className="dual-service-image">
                <img src={image005} alt="Financial Analysis" />
              </div>
              <div className="dual-service-content">
                <div className="service-icon">
                  <FaCalculator />
                </div>
                <h3 className="service-title">Financial Analysis</h3>
                <p className="service-text">
                  We offer superior financial analysis to help clients understand the economic implications of their property choices. This includes evaluating lease terms, conducting cost-benefit analyses, and identifying opportunities for cost savings.
                </p>
              </div>
            </motion.div>

            {services.slice(4, 7).map((service, index) => (
              <motion.div 
                key={index + 4}
                className="service-item"
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: (index + 4) * 0.1, duration: 0.5 }}
              >
                <div className="service-content">
                  <div className="service-icon">
                    {service.icon}
                  </div>
                  <h3 className="service-title">{service.title}</h3>
                  <p className="service-text">{service.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* 联系部分 */}
      <div className="contact-section">
        <div className="contact-overlay"></div>
        <div className="contact-pattern"></div>
        <div className="contact-content">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Ready to Find Your Perfect Space?
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1, duration: 0.6 }}
          >
            At The One Prestige, we are committed to providing exceptional tenant representation services that maximize opportunities and minimize challenges. Our expertise, combined with our personalized approach, ensures that we deliver tailored solutions that meet the unique needs of each tenant.
          </motion.p>
          <Link to="/contact">
            <motion.div 
              className="contact-button"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Contact us
            </motion.div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TenantRepresentative; 