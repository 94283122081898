import React, { useEffect } from 'react';
import '../styles/ResidentialSell.css';
import image002 from '../assets/images/residential_sell/002.jpeg';
import image003 from '../assets/images/residential_sell/003.jpeg';
import image004 from '../assets/images/residential_sell/004.jpeg';
import image005 from '../assets/images/residential_sell/005.jpeg';
import image006 from '../assets/images/residential_sell/006.jpeg';
import image007 from '../assets/images/residential_sell/007.jpeg';
import { motion } from 'framer-motion';

const ResidentialSell = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textReveal = {
    hidden: { opacity: 0, y: 30 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.7,
        delay: custom * 0.2,
        ease: [0.42, 0, 0.58, 1]
      }
    })
  };

  return (
    <div className="rs-luxury-residential-sell">
      {/* 优雅的头部区域 - 使用设计性背景 */}
      <div className="rs-luxury-hero-section">
        <div className="rs-designer-background">
          <div className="rs-gradient-overlay"></div>
          <div className="rs-pattern-overlay"></div>
          <div className="rs-animated-shapes">
            <div className="rs-shape rs-shape-1"></div>
            <div className="rs-shape rs-shape-2"></div>
            <div className="rs-shape rs-shape-3"></div>
          </div>
        </div>
        <motion.div 
          className="rs-luxury-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 variants={textReveal} custom={1}>THE ONE PRESTIGE</motion.h1>
          <motion.h2 variants={textReveal} custom={2}>Distinguished Property Sales</motion.h2>
          <motion.p variants={textReveal} custom={3}>
            Unlocking Exceptional Value for Exclusive Properties in Sydney's Premier Locations
          </motion.p>
          <motion.button 
            className="rs-luxury-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            Begin Your Journey
          </motion.button>
        </motion.div>
      </div>

      {/* 优雅介绍部分 */}
      <motion.div 
        className="rs-luxury-introduction"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <div className="rs-intro-content">
          <h2>Unparalleled Expertise in Sydney's Luxury Market</h2>
          <div className="rs-gold-separator"></div>
          <p>At The One Prestige, we transcend traditional real estate services to deliver an experience worthy of the properties we represent. Our bespoke approach ensures your prestigious home achieves its true market potential.</p>
        </div>
      </motion.div>

      {/* 高级服务网格 */}
      <div className="rs-luxury-services-container">
        <motion.h2 
          className="rs-section-title"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          Our Distinguished Services
        </motion.h2>
        <div className="rs-luxury-services-grid">
          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">01</span>
              <img src={image002} alt="Expert Knowledge" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Expert Knowledge</h4>
                  <h3>Elite Market Insight</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-chart-line"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>Our profound understanding of Sydney's premium market ensures your property is positioned strategically to attract discerning buyers.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">02</span>
              <img src={image003} alt="Track Record" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Track Record</h4>
                  <h3>Distinguished Track Record</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-trophy"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>Our portfolio of success stories reflects our commitment to excellence and ability to exceed the expectations of our distinguished clientele.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">03</span>
              <img src={image004} alt="Marketing Strategies" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Marketing Strategies</h4>
                  <h3>Bespoke Marketing Campaigns</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-bullhorn"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>We craft tailored marketing narratives that highlight your property's unique character, utilizing both traditional prestige channels and cutting-edge digital platforms.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">04</span>
              <img src={image005} alt="Dedicated Service" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Dedicated Service</h4>
                  <h3>White Glove Service</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-gem"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>Experience uncompromising dedication with our transparent communication and personalized attention throughout every stage of your property journey.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">05</span>
              <img src={image006} alt="Strong Network" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Strong Network</h4>
                  <h3>Global Buyer Network</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-globe"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>Our exclusive connections extend to high-net-worth individuals and international investors actively seeking premium properties in Sydney's most coveted locations.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="rs-luxury-service-card"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <div className="rs-service-image-container">
              <span className="rs-service-number">06</span>
              <img src={image007} alt="Top Negotiators" />
              <div className="rs-image-overlay"></div>
            </div>
            <div className="rs-service-content">
              <div className="rs-service-top">
                <div>
                  <h4>Top Negotiators</h4>
                  <h3>Master Negotiation</h3>
                </div>
                <div className="rs-service-icon">
                  <i className="fas fa-handshake"></i>
                </div>
              </div>
              <div className="rs-service-separator"></div>
              <p>Our elite negotiators artfully secure premium returns, ensuring your investment achieves its full potential in Sydney's competitive luxury market.</p>
              <div className="rs-service-read-more">
                Learn More <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 高雅联系部分 */}
      <div className="rs-luxury-contact-section">
        <div className="rs-contact-overlay"></div>
        <motion.div 
          className="rs-luxury-contact-content"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2>Begin Your Exceptional Journey</h2>
          <div className="rs-contact-separator"></div>
          <p>Allow our distinguished team to showcase the true value of your premium property</p>
          <div className="rs-luxury-cta-buttons">
            <motion.button 
              className="rs-luxury-primary-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Request Private Consultation
            </motion.button>
            <motion.button 
              className="rs-luxury-secondary-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Contact Our Directors
            </motion.button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ResidentialSell; 