import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/HomePage.css';
// 导入图片
import hero01 from '../assets/images/home/hero_01.jpg';
import hero02 from '../assets/images/home/hero_02.jpg';
import hero03 from '../assets/images/home/hero_03.jpg';

const HomePage = () => {
  const [inView, setInView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(5);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [heroSlideIndex, setHeroSlideIndex] = useState(0);
  const [leavingIndex, setLeavingIndex] = useState(null);
  const slidesRef = useRef([]);
  
  // Hero轮播文字数据
  const heroSlides = [
    {
      title: "THE ONE PRESTIGE",
      subtitle: "Whatever the market standard offers, we offer more …",
      description: "Welcome to THE ONE PRESTIGE, your premier destination for exceptional real estate services in Barangaroo, Sydney, Australia.",
      backgroundImage: hero01
    },
    {
      title: "THE ONE PRESTIGE",
      subtitle: "Asking us anytime, experiencing the elements of our premium services …",
      description: "Welcome to THE ONE PRESTIGE, your premier destination for exceptional real estate services in Barangaroo, Sydney, Australia.",
      backgroundImage: hero02
    },
    {
      title: "THE ONE PRESTIGE",
      subtitle: "Whatever the market standard offers, we offer more …",
      description: "Welcome to THE ONE PRESTIGE, your premier destination for exceptional real estate services in Barangaroo, Sydney, Australia.",
      backgroundImage: hero03
    }
  ];
  
  // 处理Hero轮播，优化逻辑避免动画卡顿
  useEffect(() => {
    let timeoutIds = []; // 存储所有timeout ID以便清理
    
    const startCarousel = () => {
      // 当前幻灯片索引
      const currentIndex = heroSlideIndex;
      // 下一个幻灯片索引
      const nextIndex = (currentIndex + 1) % heroSlides.length;
      
      // 清理当前可能存在的所有timeout
      timeoutIds.forEach(id => clearTimeout(id));
      timeoutIds = [];
      
      // 1. 设置当前幻灯片为离场状态
      setLeavingIndex(currentIndex);
      
      // 2. 等待离场动画完成（约2秒）
      const timeout1 = setTimeout(() => {
        // 3. 离场动画结束后，等待0.5秒过渡期
        const timeout2 = setTimeout(() => {
          // 4. 切换到下一张幻灯片
          setHeroSlideIndex(nextIndex);
          // 5. 0.1秒后清除离场状态
          const timeout3 = setTimeout(() => {
            setLeavingIndex(null);
          }, 100);
          timeoutIds.push(timeout3);
        }, 100); // 0.1秒过渡期
        timeoutIds.push(timeout2);
      }, 500); // 离场动画时间
      timeoutIds.push(timeout1);
    };
    
    // 设置轮播间隔
    const carouselTimeout = setTimeout(startCarousel, 12000); // 12秒一个完整轮播周期
    timeoutIds.push(carouselTimeout);
    
    // 组件卸载时清理所有timeout
    return () => {
      timeoutIds.forEach(id => clearTimeout(id));
    };
  }, [heroSlideIndex, heroSlides.length]); // 添加 heroSlideIndex 和 heroSlides.length 作为依赖
  
  // 处理卡片点击事件
  const handleCardClick = (index) => {
    if (activeCardIndex === index) {
      setActiveCardIndex(null); // 再次点击同一张卡片时关闭
    } else {
      setActiveCardIndex(index); // 点击其他卡片时打开
    }
  };
  
  // 处理轮播暂停和恢复
  const handleCarouselPause = () => {
    setIsPaused(true);
  };

  const handleCarouselResume = () => {
    setIsPaused(false);
  };
  
  // 轮播卡片数据
  const carouselItems = [
    {
      icon: 'fas fa-award',
      title: 'Results Driven',
      content: 'At THE ONE, we focus on delivering exceptional outcomes, ensuring the best value and experience for every client.'
    },
    {
      icon: 'fas fa-balance-scale',
      title: 'Integrity Above All',
      content: 'We are committed to honesty, transparency, and ethical practices in every transaction.'
    },
    {
      icon: 'fas fa-user-tie',
      title: 'Client-Centered',
      content: 'Your goals are our priority, and we tailor every strategy to meet your unique needs.'
    },
    {
      icon: 'fas fa-star',
      title: 'Passion for Excellence',
      content: 'We take pride in our work, striving for the highest standards and consistently exceeding expectations.'
    },
    {
      icon: 'fas fa-lightbulb',
      title: 'Innovation & Adaptability',
      content: 'We embrace new technologies and marketing strategies to stay ahead in a competitive market.'
    },
    {
      icon: 'fas fa-globe',
      title: 'Global Perspective',
      content: 'International insights and market knowledge to maximize your property investment opportunities.'
    },
    {
      icon: 'fas fa-handshake',
      title: 'Dedicated Partnership',
      content: 'Building lasting relationships through personalized service and unwavering commitment.'
    },
    {
      icon: 'fas fa-shield-alt',
      title: 'Trust & Reliability',
      content: 'Years of industry experience and a proven track record of successful property transactions.'
    }
  ];
  
  // 根据屏幕宽度计算一页显示的卡片数量
  const calculateVisibleItems = () => {
    if (window.innerWidth < 480) return 1;
    if (window.innerWidth < 768) return 2;
    if (window.innerWidth < 992) return 3;
    if (window.innerWidth < 1200) return 4;
    return 5;
  };

  // 计算要滚动的百分比
  const getScrollPercentage = () => {
    return (100 / visibleItems);
  };
  
  // 监听窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      setVisibleItems(calculateVisibleItems());
    };
    
    // 初始化
    handleResize();
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    // 初始化AOS动画库
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      offset: 120
    });
    
    const statsSection = document.querySelector('.stats-container');
    
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setInView(true);
        observer.disconnect();
      }
    }, { threshold: 0.3 });
    
    if (statsSection) {
      observer.observe(statsSection);
    }
    
    return () => {
      if (statsSection) {
        observer.unobserve(statsSection);
      }
    };
  }, []);
  
  // 处理自动轮播逻辑
  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setActiveIndex((prevIndex) => prevIndex + 1);
      }, 3000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPaused]);

  // 监听卡片滚动
  useEffect(() => {
    if (activeIndex >= carouselItems.length) {
      // 当滚动到末尾时，立即重置到原始位置（无过渡效果）
      setTimeout(() => {
        const carouselTrack = document.querySelector('.carousel-track');
        if (carouselTrack) {
          carouselTrack.style.transition = 'none';
          setActiveIndex(0);
          // 重新启用过渡效果
          setTimeout(() => {
            carouselTrack.style.transition = 'transform 0.6s ease-in-out';
          }, 50);
        }
      }, 600); // 等待过渡完成
    }
  }, [activeIndex, carouselItems.length]);

  return (
    <div className="home-page">
      <div className="hero-section">
        <div className="hero-content">
          <div className="hero-slide-container">
            {heroSlides.map((slide, index) => (
              <div 
                key={index} 
                className={`hero-slide ${index === heroSlideIndex ? 'active' : ''} ${index === leavingIndex ? 'leaving' : ''}`}
                ref={el => slidesRef.current[index] = el}
              >
                <div 
                  className="hero-slide-bg" 
                  style={{ backgroundImage: `linear-gradient(rgba(0, 18, 51, 0.5), rgba(0, 0, 0, 0.5)), url(${slide.backgroundImage})` }}
                ></div>
                <h1 className="hero-title">{slide.title}</h1>
                <h2 className="hero-subtitle">{slide.subtitle}</h2>
                <p className="hero-description">{slide.description}</p>
                <button className="hero-explore-btn">explore</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div className="discover-section">
        <div className="section-divider" data-aos="fade-down">
          <span className="divider-icon">◇◇◇</span>
        </div>
        <h2 className="section-title" data-aos="fade-up">DISCOVER THE ONE PRESTIGE</h2>
        <p className="section-text" data-aos="fade-up" data-aos-delay="200">
          AT THE ONE PRESTIGE TEAM, we are committed to delivering exceptional results 
          with unmatched expertise in Sydney's dynamic property market. Discover the 
          difference of working with a team that values integrity, professionalism, and 
          results.
        </p>
      </div>

      <div className="contact-section">
        <h3 className="contact-subtitle" data-aos="fade-up">GET IN TOUCH</h3>
        <h2 className="contact-title" data-aos="fade-up" data-aos-delay="100">WE LOVE TO HEAR FROM YOU</h2>
        
        <div className="stats-container">
          <div className="stat-item" data-aos="fade-up">
            <h2 className="stat-number">
              {inView && (
                <CountUp 
                  start={0} 
                  end={8166757} 
                  duration={2.5} 
                  separator="," 
                  useEasing={true}
                  className="count-animation"
                />
              )}
              {!inView && '0'}
            </h2>
            <p className="stat-label">NSW POPULATION</p>
          </div>
          <div className="stat-item" data-aos="fade-up" data-aos-delay="200">
            <h2 className="stat-number">
              {inView && (
                <CountUp 
                  start={0} 
                  end={85000} 
                  duration={2.5} 
                  separator="," 
                  useEasing={true}
                  className="count-animation"
                />
              )}
              {!inView && '0'}
            </h2>
            <p className="stat-label">GROWTH EACH YEAR</p>
          </div>
          <div className="stat-item" data-aos="fade-up" data-aos-delay="400">
            <h2 className="stat-number">
              {inView && (
                <CountUp 
                  start={0} 
                  end={3364777} 
                  duration={2.5} 
                  separator="," 
                  useEasing={true}
                  className="count-animation"
                />
              )}
              {!inView && '0'}
            </h2>
            <p className="stat-label">TOTAL DWELLINGS</p>
          </div>
          <div className="stat-item" data-aos="fade-up" data-aos-delay="600">
            <h2 className="stat-number">
              {inView && (
                <CountUp 
                  start={0} 
                  end={45200} 
                  duration={2.5} 
                  separator="," 
                  useEasing={true}
                  className="count-animation"
                />
              )}
              {!inView && '0'}
            </h2>
            <p className="stat-label">NEW HOME NEEDED PER YEAR</p>
          </div>
        </div>
      </div>

      {/* 黄金价值亮点部分 */}
      <div className="gold-value-section">
        <div className="section-divider" data-aos="fade-down">
          <span className="divider-icon">◇◇◇</span>
        </div>
        <h3 className="highlight-subtitle" data-aos="fade-up">HIGHLIGHTS FEATURES</h3>
        <h2 className="section-title" data-aos="fade-up">GOLD VALUE OF US</h2>
        <p className="subtitle-text" data-aos="fade-up">EXQUISITE MANAGEMENT AND EXCEPTIONAL PROPERTY SOLUTIONS</p>
        <div className="gold-highlights" data-aos="fade-up" data-aos-delay="200">
          <div 
            className={`gold-highlight-item position-left ${activeCardIndex === 0 ? 'active' : ''}`}
            onClick={() => handleCardClick(0)}
          >
            <div className="highlight-icon">
              <i className="fas fa-crown"></i>
            </div>
            <h3>Premium Management</h3>
            <p className="highlight-brief">Elite property management services tailored to luxury investments</p>
            <div className="highlight-details">
              <p>Personalized attention for each property, maintaining prestige value and operational excellence.</p>
              <p>Comprehensive maintenance programs preserving aesthetic and functional aspects.</p>
              <p>Advanced security protocols designed for high-value properties and discerning clients.</p>
            </div>
            <div className="card-glow"></div>
            <div className="view-more-indicator"><i className="fas fa-chevron-right"></i></div>
          </div>
          <div 
            className={`gold-highlight-item position-center ${activeCardIndex === 1 ? 'active' : ''}`}
            onClick={() => handleCardClick(1)}
          >
            <div className="highlight-icon">
              <i className="fas fa-gem"></i>
            </div>
            <h3>Elevate Your Investments, Experience Luxury</h3>
            <p className="highlight-brief">Transform your property portfolio with our unparalleled expertise</p>
            <div className="highlight-details">
              <p>Strategic guidance identifying prime opportunities in exclusive markets.</p>
              <p>Seamless acquisition process from consultation to property handover.</p>
              <p>Value-enhancement strategies maximizing returns while preserving character.</p>
            </div>
            <div className="card-glow"></div>
            <div className="view-more-indicator"><i className="fas fa-plus"></i></div>
          </div>
          <div 
            className={`gold-highlight-item position-right ${activeCardIndex === 2 ? 'active' : ''}`}
            onClick={() => handleCardClick(2)}
          >
            <div className="highlight-icon">
              <i className="fas fa-handshake"></i>
            </div>
            <h3>Exceptional Solutions</h3>
            <p className="highlight-brief">Tailored property solutions that exceed the highest expectations</p>
            <div className="highlight-details">
              <p>Custom solutions addressing unique challenges of luxury real estate investments.</p>
              <p>Dedicated concierge services for all property-related matters.</p>
              <p>Exclusive access to premium service providers for elite-level care.</p>
            </div>
            <div className="card-glow"></div>
            <div className="view-more-indicator"><i className="fas fa-chevron-left"></i></div>
          </div>
        </div>
      </div>

      {/* 自动轮播卡片部分 */}
      <div className="carousel-section">
        <div className="carousel-container" data-aos="fade-up">
          <div className="carousel-track" style={{ transform: `translateX(-${activeIndex * getScrollPercentage()}%)` }}>
            {/* 显示原始卡片加复制卡片，以实现无限滚动效果 */}
            {carouselItems.map((item, index) => {
              // 计算当前卡片的位置状态
              const position = (index - activeIndex) % carouselItems.length;
              const normalizedPosition = position < 0 ? position + carouselItems.length : position;
              
              // 根据位置分配样式类
              let positionClass = '';
              // 计算中间卡片位置（基于当前显示的卡片数量）
              const centerPosition = Math.floor(visibleItems / 2);
              
              if (normalizedPosition === centerPosition) {
                positionClass = 'center-active'; // 中间卡片
              } else if (normalizedPosition === centerPosition - 1 || normalizedPosition === centerPosition + 1) {
                positionClass = 'side-active'; // 两侧第一个卡片
              } else {
                positionClass = 'far-active'; // 其他卡片
              }
              
              return (
                <div 
                  key={index} 
                  className={`carousel-item ${positionClass}`}
                  onMouseEnter={normalizedPosition === centerPosition ? handleCarouselPause : undefined}
                  onMouseLeave={normalizedPosition === centerPosition ? handleCarouselResume : undefined}
                >
                  <div className="carousel-icon">
                    <i className={item.icon}></i>
                  </div>
                  <h3 className="carousel-title">{item.title}</h3>
                  <p className="carousel-content">{item.content}</p>
                </div>
              );
            })}
            
            {/* 复制一组卡片用于无缝循环 */}
            {carouselItems.map((item, index) => {
              // 计算复制卡片的位置状态
              const position = (index + carouselItems.length - activeIndex) % carouselItems.length;
              const normalizedPosition = position < 0 ? position + carouselItems.length : position;
              
              // 根据位置分配样式类
              let positionClass = '';
              // 计算中间卡片位置（基于当前显示的卡片数量）
              const centerPosition = Math.floor(visibleItems / 2);
              
              if (normalizedPosition === centerPosition) {
                positionClass = 'center-active'; // 中间卡片
              } else if (normalizedPosition === centerPosition - 1 || normalizedPosition === centerPosition + 1) {
                positionClass = 'side-active'; // 两侧第一个卡片
              } else {
                positionClass = 'far-active'; // 其他卡片
              }
              
              return (
                <div 
                  key={`clone-${index}`} 
                  className={`carousel-item ${positionClass}`}
                  onMouseEnter={normalizedPosition === centerPosition ? handleCarouselPause : undefined}
                  onMouseLeave={normalizedPosition === centerPosition ? handleCarouselResume : undefined}
                >
                  <div className="carousel-icon">
                    <i className={item.icon}></i>
                  </div>
                  <h3 className="carousel-title">{item.title}</h3>
                  <p className="carousel-content">{item.content}</p>
                </div>
              );
            })}
          </div>
          
          <div className="carousel-dots">
            {carouselItems.map((_, index) => (
              <span 
                key={index} 
                className={`carousel-dot ${activeIndex % carouselItems.length === index ? 'active' : ''}`}
                onClick={() => setActiveIndex(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage; 