import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaRegLightbulb, FaCubes, FaChartLine, FaSearch, FaUsers, FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';
import '../styles/ResidentialProjectManagement.css';
import projectImage from '../assets/images/residential_project_management/001.jpeg';

const ResidentialProjectManagement = () => {
  const canvasRef = useRef(null);
  const requestRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  // 3D网格背景动画
  useEffect(() => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // 设置Canvas尺寸
    const setupCanvas = () => {
      const { width, height } = canvas.getBoundingClientRect();
      const dpr = window.devicePixelRatio || 1;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      ctx.scale(dpr, dpr);
      return { width, height };
    };
    
    let { width, height } = setupCanvas();
    
    // 网格点类
    class GridPoint {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.baseY = y;
        this.amplitude = Math.random() * 8 + 3;
        this.speed = Math.random() * 0.01 + 0.005;
        this.phase = Math.random() * Math.PI * 2;
      }
      
      update(time) {
        this.y = this.baseY + Math.sin(time * this.speed + this.phase) * this.amplitude;
      }
    }
    
    // 创建网格
    const gridSpacing = 80;
    const gridPoints = [];
    const rows = Math.ceil(height / gridSpacing) + 2;
    const cols = Math.ceil(width / gridSpacing) + 2;
    
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
        gridPoints.push(new GridPoint(
          x * gridSpacing - gridSpacing / 2,
          y * gridSpacing - gridSpacing / 2
        ));
      }
    }
    
    // 绘制函数
    const draw = (time) => {
      ctx.clearRect(0, 0, width, height);
      
      // 绘制背景渐变
      const gradient = ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, '#0a1c3d');
      gradient.addColorStop(1, '#132952');
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);
      
      // 绘制网格
      ctx.strokeStyle = 'rgba(207, 180, 131, 0.1)';
      ctx.lineWidth = 0.5;
      
      // 减慢动画速度，除以2来减缓
      const slowedTime = time / 2;
      
      // 更新网格点
      gridPoints.forEach(point => point.update(slowedTime));
      
      // 绘制水平线
      for (let row = 0; row < rows; row++) {
        ctx.beginPath();
        for (let col = 0; col < cols; col++) {
          const index = row * cols + col;
          const point = gridPoints[index];
          if (col === 0) {
            ctx.moveTo(point.x, point.y);
          } else {
            ctx.lineTo(point.x, point.y);
          }
        }
        ctx.stroke();
      }
      
      // 绘制垂直线
      for (let col = 0; col < cols; col++) {
        ctx.beginPath();
        for (let row = 0; row < rows; row++) {
          const index = row * cols + col;
          const point = gridPoints[index];
          if (row === 0) {
            ctx.moveTo(point.x, point.y);
          } else {
            ctx.lineTo(point.x, point.y);
          }
        }
        ctx.stroke();
      }
      
      // 绘制网格节点
      ctx.fillStyle = 'rgba(207, 180, 131, 0.3)';
      gridPoints.forEach(point => {
        ctx.beginPath();
        ctx.arc(point.x, point.y, 1, 0, Math.PI * 2);
        ctx.fill();
      });
      
      // 绘制特殊效果节点
      const specialPoints = gridPoints.filter(() => Math.random() < 0.02);
      specialPoints.forEach(point => {
        const size = Math.random() * 2 + 1;
        ctx.beginPath();
        ctx.arc(point.x, point.y, size, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(207, 180, 131, 0.6)';
        ctx.fill();
      });
      
      // 绘制几何金色装饰
      drawGoldAccents(ctx, width, height, time);
      
      requestRef.current = requestAnimationFrame(draw);
    };
    
    const drawGoldAccents = (ctx, width, height, time) => {
      // 绘制几何线条
      ctx.strokeStyle = 'rgba(207, 180, 131, 0.2)';
      ctx.lineWidth = 1;
      
      // 几个对角线
      ctx.beginPath();
      ctx.moveTo(0, height * 0.2);
      ctx.lineTo(width * 0.3, height * 0.1);
      ctx.stroke();
      
      ctx.beginPath();
      ctx.moveTo(width, height * 0.8);
      ctx.lineTo(width * 0.7, height * 0.9);
      ctx.stroke();
      
      // 绘制装饰几何图形 - 减慢动画速度
      const numShapes = 3;
      for (let i = 0; i < numShapes; i++) {
        const x = width * (0.2 + i * 0.3);
        const y = height * 0.85;
        // 减慢几何图形的动画
        const size = 30 + Math.sin(time * 0.0004 + i) * 5;
        
        ctx.strokeStyle = 'rgba(207, 180, 131, 0.15)';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.rect(x - size/2, y - size/2, size, size);
        ctx.stroke();
        
        ctx.beginPath();
        ctx.arc(x, y, size * 0.7, 0, Math.PI * 2);
        ctx.stroke();
      }
    };
    
    // 开始动画
    requestRef.current = requestAnimationFrame(draw);
    
    // 响应窗口大小变化
    const handleResize = () => {
      ({ width, height } = setupCanvas());
    };
    
    window.addEventListener('resize', handleResize);
    
    // 清理函数
    return () => {
      window.removeEventListener('resize', handleResize);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);
  
  // 页面加载状态
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }, []);
  
  // 项目管理关键组件数据
  const keyComponents = [
    {
      title: "Project Planning and Design",
      icon: <FaRegLightbulb />,
      description: "Comprehensive planning including conceptualization, design development, and detailed blueprint creation for successful project execution."
    },
    {
      title: "Resource Management",
      icon: <FaCubes />,
      description: "Efficient allocation of materials, equipment, and human resources to ensure timely project completion without compromising quality."
    },
    {
      title: "Budget Control",
      icon: <FaChartLine />,
      description: "Strict financial oversight to maintain cost-effectiveness, prevent overruns, and deliver value while meeting quality standards."
    },
    {
      title: "Quality Assurance",
      icon: <FaSearch />,
      description: "Rigorous quality control processes to ensure all construction meets or exceeds industry standards and client expectations."
    },
    {
      title: "Stakeholder Communication",
      icon: <FaUsers />,
      description: "Clear and consistent communication with all project stakeholders to maintain alignment and address concerns promptly."
    },
    {
      title: "Risk Management",
      icon: <FaExclamationTriangle />,
      description: "Proactive identification and mitigation of potential risks to minimize disruptions and ensure project continuity."
    },
    {
      title: "Post-Completion Evaluation",
      icon: <FaCheckCircle />,
      description: "Thorough assessment of completed projects to ensure client satisfaction and identify areas for future improvement."
    }
  ];

  // 动画变量
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="project-management">
      {/* 英雄部分 - 创新3D网格背景 */}
      <section className="management-hero-section">
        <canvas ref={canvasRef} className="grid-canvas"></canvas>
        
        <div className="hero-overlay-elements">
          <div className="vertical-line line-1"></div>
          <div className="vertical-line line-2"></div>
          <div className="horizontal-line line-3"></div>
          <div className="diagonal-line line-4"></div>
          <div className="circle-element"></div>
          <div className="square-element"></div>
        </div>
        
        <div className="hero-content-wrapper">
          <motion.div 
            className="management-hero-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: isLoaded ? 1 : 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <h1>THE ONE PRESTIGE</h1>
            <div className="hero-separator"></div>
            <h2 style={{ fontStyle: 'normal' }}>Turning Vision into Reality, One Project at a Time.</h2>
            <h3>Residential Project Management: Key to Success</h3>
          </motion.div>
        </div>
      </section>

      {/* 介绍部分 */}
      <section className="management-intro-section">
        <div className="container">
          <motion.div 
            className="intro-content"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
          >
            <p>
              Successful residential project management is the cornerstone of turning architectural visions into reality. It requires a meticulous approach that balances time, resources, and quality to deliver exceptional living spaces that exceed client expectations.
            </p>
          </motion.div>
        </div>
      </section>

      {/* 关键组件部分 */}
      <section className="key-components-section">
        <div className="container">
          <motion.h2 
            className="section-title"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            variants={fadeInUp}
          >
            Key Components of Project Management
          </motion.h2>
          
          <motion.div 
            className="component-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            {keyComponents.map((component, index) => (
              <motion.div 
                className="component-card" 
                key={index}
                variants={fadeInUp}
              >
                <div className="card-bg-elements">
                  <div className="element element-1"></div>
                  <div className="element element-2"></div>
                  <div className="corner-accent"></div>
                </div>
                
                <div className="card-content">
                  <div className="icon-container">
                    {component.icon}
                  </div>
                  {index === keyComponents.length - 1 ? (
                    <div className="text-content">
                      <h3>{component.title}</h3>
                      <p>{component.description}</p>
                    </div>
                  ) : (
                    <>
                      <h3>{component.title}</h3>
                      <p>{component.description}</p>
                    </>
                  )}
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* 图片展示部分 */}
      <section className="showcase-section">
        <motion.div 
          className="showcase-parallax"
          initial={{ y: 0 }}
          whileInView={{ y: -50 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div className="img-container">
            <img src={projectImage} alt="Residential Project" />
            <div className="overlay"></div>
          </div>
        </motion.div>
        
        <div className="showcase-content">
          <motion.div 
            className="content-wrapper"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
          >
            <h2>Excellence in Every Detail</h2>
            <div className="content-separator"></div>
            <p>
              At The One Prestige, we apply our expertise to every project, ensuring that each residential development is completed to the highest standards of quality, efficiency, and sustainability.
            </p>
            <button className="contact-button">CONTACT US</button>
          </motion.div>
        </div>
      </section>

      {/* CTA 部分 */}
      <section className="cta-section">
        <div className="container">
          <motion.div 
            className="cta-content"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
          >
            <h2>Ready to Start Your Next Project?</h2>
            <p>
              Let our expert project management team guide your residential development from concept to completion.
            </p>
            <div className="cta-buttons">
              <button className="primary-btn">GET A QUOTE</button>
              <button className="secondary-btn">LEARN MORE</button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default ResidentialProjectManagement; 