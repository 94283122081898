import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaTools, FaChartLine, FaCalendarAlt, FaCheck, FaShieldAlt } from 'react-icons/fa';
import '../styles/DevelopmentManagement.css';

// 导入图片
import image001 from '../assets/images/finance/001.jpeg';
import image002 from '../assets/images/finance/002.jpeg';

const DevelopmentManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 核心服务数据
  const coreServices = [
    {
      title: "Project Planning and Coordination",
      description: "We manage the entire planning process, from initial concept development to detailed design and approvals. Our team ensures that all project components align with your goals, and we coordinate with architects, engineers, and other consultants to bring your project to life.",
      icon: <FaChartLine />
    },
    {
      title: "Budget Management and Cost Control",
      description: "We provide meticulous oversight of your project's budget, ensuring that costs are controlled and financial goals are met. Our team works to identify cost-saving opportunities without compromising on quality, helping you maximize your return on investment.",
      icon: <FaTools />
    },
    {
      title: "Timeline Management",
      description: "Keeping your project on schedule is a top priority. We develop and manage detailed project timelines, coordinating with all stakeholders to ensure milestones are met and the project progresses smoothly from start to finish.",
      icon: <FaCalendarAlt />
    },
    {
      title: "Quality Assurance",
      description: "We oversee the construction process to ensure that all work meets the highest standards of quality. Our team conducts regular site inspections, manages contractor performance, and ensures that all materials and workmanship adhere to project specifications.",
      icon: <FaCheck />
    },
    {
      title: "Risk Management and Compliance",
      description: "Navigating the complexities of development requires careful risk management. We identify potential risks and implement strategies to mitigate them, ensuring that your project complies with all regulatory requirements and industry best practices.",
      icon: <FaShieldAlt />
    }
  ];

  return (
    <div className="development-management">
      {/* 高端视觉头部区域 */}
      <div className="management-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="management-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
            style={{ fontStyle: 'normal' }}
          >Development Management</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Guiding your project from concept to completion</motion.h3>
          <motion.button 
            className="management-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="management-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Development Management Services</h2>
          <div className="gold-separator"></div>
          <p>
            Our Development Management services are dedicated to guiding your property development projects from concept to completion. We act as your trusted partner, overseeing every aspect of the development process to ensure your vision is realized on time, within budget, and to the highest standards of quality.
          </p>
          <p>
            Our team combines strategic thinking with practical implementation expertise to navigate the complexities of modern property development. We serve as your dedicated partner, managing all aspects of your project to ensure successful delivery that meets your expectations.
          </p>
          <p className="highlighted-text">
            "Our Development Management services are designed to provide you with peace of mind, knowing that your project is in capable hands."
          </p>
          <p>
            We are committed to delivering successful outcomes, ensuring that your development is completed to your satisfaction and contributes positively to your overall investment strategy. Whether you're an experienced developer seeking specialized support or an investor looking for end-to-end project management, our tailored approach ensures your development objectives are met with precision and excellence.
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Development Management Services" />
        </motion.div>
      </div>

      {/* 核心服务部分 */}
      <div className="core-services-section">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Core Services
        </motion.h2>
        <div className="services-grid">
          {coreServices.map((service, index) => (
            <motion.div 
              key={index}
              className="service-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7, 
                delay: index * 0.1,
                ease: "easeOut" 
              }}
              whileHover={{ 
                y: -10,
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                transition: { duration: 0.3 } 
              }}
            >
              <div className="service-icon">
                {service.icon}
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 项目周期部分 */}
      <div className="project-lifecycle-section">
        <div className="lifecycle-background">
          <img src={image002} alt="Project Lifecycle" />
          <div className="overlay"></div>
        </div>
        <motion.div 
          className="lifecycle-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2>Development Lifecycle Expertise</h2>
          <div className="lifecycle-stages">
            <div className="lifecycle-stage">
              <div className="stage-number">01</div>
              <div className="stage-details">
                <h3>Concept & Feasibility</h3>
                <p>Initial project concept development, market analysis, and feasibility assessment to validate development potential.</p>
              </div>
            </div>
            <div className="lifecycle-stage">
              <div className="stage-number">02</div>
              <div className="stage-details">
                <h3>Planning & Approvals</h3>
                <p>Strategic planning, design development, and navigation of regulatory approvals to secure development permissions.</p>
              </div>
            </div>
            <div className="lifecycle-stage">
              <div className="stage-number">03</div>
              <div className="stage-details">
                <h3>Procurement & Construction</h3>
                <p>Contractor selection, construction management, quality control, and progress monitoring throughout the build phase.</p>
              </div>
            </div>
            <div className="lifecycle-stage">
              <div className="stage-number">04</div>
              <div className="stage-details">
                <h3>Completion & Handover</h3>
                <p>Final inspections, defect management, documentation completion, and smooth transition to operational status.</p>
              </div>
            </div>
          </div>
          <Link to="/contact" className="contact-link">
            Discuss Your Development Project
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default DevelopmentManagement; 