import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSearchDollar, FaBuilding, FaClipboardCheck, FaChartPie, FaClipboardList } from 'react-icons/fa';
import '../styles/DevelopmentFeasibility.css';

// 导入图片
import image001 from '../assets/images/finance/001.jpeg';
import image002 from '../assets/images/finance/002.jpeg';

const DevelopmentFeasibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 核心服务数据
  const coreServices = [
    {
      title: "Site Assessment and Analysis",
      description: "We evaluate potential development sites to determine their suitability for your project. This includes analysing zoning regulations, site constraints, and environmental considerations to identify any potential challenges and opportunities.",
      icon: <FaBuilding />
    },
    {
      title: "Financial Feasibility Studies",
      description: "Our team conducts thorough financial assessments to estimate project costs, potential revenue, and overall profitability. We provide you with a clear understanding of the financial implications of your development, helping you to make informed decisions on project viability.",
      icon: <FaChartPie />
    },
    {
      title: "Market Research and Demand Analysis",
      description: "Understanding the market is crucial for any development project. We provide in-depth market research, including analysis of current and future demand trends, to ensure your development meets market needs and is positioned for success.",
      icon: <FaSearchDollar />
    },
    {
      title: "Risk Assessment",
      description: "We identify potential risks associated with your development project, including financial, environmental, and regulatory risks. Our team offers strategic recommendations to mitigate these risks and enhance the likelihood of project success.",
      icon: <FaClipboardCheck />
    },
    {
      title: "Planning and Regulatory Advice",
      description: "Navigating the complex planning and regulatory environment can be challenging. We offer guidance on meeting planning requirements, securing approvals, and complying with local regulations to ensure your project progresses smoothly.",
      icon: <FaClipboardList />
    }
  ];

  return (
    <div className="development-feasibility">
      {/* 高端视觉头部区域 */}
      <div className="feasibility-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="feasibility-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Development Feasibility</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Making informed decisions at every stage of your project</motion.h3>
          <motion.button 
            className="feasibility-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="feasibility-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Development Feasibility Services</h2>
          <div className="gold-separator"></div>
          <p>
            Our Development Feasibility services are designed to help you make informed decisions at every stage of your property development project. We provide detailed analysis and strategic advice, ensuring that your development plans are not only viable but also positioned for maximum success.
          </p>
          <p>
            We are committed to helping you make well-informed, strategic decisions that lead to successful property development outcomes. Our Development Feasibility services provide you with the insights and support you need to turn your vision into reality.
          </p>
          <p className="highlighted-text">
            "Our comprehensive feasibility studies serve as the foundation for successful development projects, providing clarity and confidence in your investment decisions."
          </p>
          <p>
            We understand that every development project is unique, which is why our feasibility assessments are tailored to your specific objectives, timeline, and budget constraints. Our goal is to equip you with the insights needed to proceed with confidence or to recalibrate your approach based on sound analysis.
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Development Feasibility Services" />
        </motion.div>
      </div>

      {/* 核心服务部分 */}
      <div className="service-process-section">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Core Services
        </motion.h2>
        <div className="process-timeline">
          {coreServices.map((service, index) => (
            <motion.div 
              key={index}
              className="process-step"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7, 
                delay: index * 0.1,
                ease: "easeOut" 
              }}
            >
              <div className="step-number">{index + 1}</div>
              <div className="step-content">
                <div className="step-icon">
                  {service.icon}
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
              {index < coreServices.length - 1 && <div className="step-connector"></div>}
            </motion.div>
          ))}
        </div>
      </div>

      {/* 服务优势部分 */}
      <div className="service-advantages">
        <div className="advantages-background">
          <img src={image002} alt="Development Feasibility Advantages" />
          <div className="overlay"></div>
        </div>
        <motion.div 
          className="advantages-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="advantages-icon">
            <FaSearchDollar />
          </div>
          <h2>Why Choose Our Feasibility Services</h2>
          <ul className="advantages-list">
            <li>
              <strong>Strategic Insight:</strong> Benefit from our deep market knowledge and strategic understanding of development dynamics.
            </li>
            <li>
              <strong>Risk Mitigation:</strong> Identify and address potential challenges before they impact your project timeline or budget.
            </li>
            <li>
              <strong>Financial Clarity:</strong> Gain precise financial projections that account for all aspects of your development project.
            </li>
            <li>
              <strong>Optimized Value:</strong> Discover opportunities to enhance project value and achieve superior returns on investment.
            </li>
          </ul>
          <Link to="/contact" className="contact-link">
            Request a Feasibility Consultation
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default DevelopmentFeasibility; 