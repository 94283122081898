import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaBriefcase, FaChartLine, FaHandshake, FaComments, FaUserTie } from 'react-icons/fa';
import '../styles/CapitalTransactions.css';

// 导入图片
import image001 from '../assets/images/capital_transactions/001.jpeg';
import image002 from '../assets/images/capital_transactions/002.jpeg';
import image003 from '../assets/images/capital_transactions/003.jpeg';
import image004 from '../assets/images/capital_transactions/004.jpeg';
import image005 from '../assets/images/capital_transactions/005.jpeg';
import image006 from '../assets/images/capital_transactions/006.jpeg';
import image007 from '../assets/images/capital_transactions/007.jpeg';
import image008 from '../assets/images/capital_transactions/008.jpeg';
import image009 from '../assets/images/capital_transactions/009.jpeg';

const CapitalTransactions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 能力数据
  const capabilities = [
    {
      title: "Expertise and Experience",
      description: "With extensive experience in commercial property transactions, our team brings deep industry knowledge and a proven track record of success, ensuring that every transaction is handled with expertise and precision.",
      icon: <FaBriefcase />
    },
    {
      title: "Comprehensive Market Insight",
      description: "We offer unparalleled insights into market trends, valuation, and investment opportunities, enabling us to provide strategic advice and identify the most lucrative prospects for our clients.",
      icon: <FaChartLine />
    },
    {
      title: "Transparency and Integrity",
      description: "We maintain the highest standards of transparency and integrity throughout the transaction process, fostering trust and confidence in every interaction.",
      icon: <FaHandshake />
    },
    {
      title: "Negotiation Expertise",
      description: "Our skilled negotiators are adept at securing the best terms and conditions for our clients, optimizing value and ensuring favorable outcomes.",
      icon: <FaComments />
    },
    {
      title: "Client-Centric Approach",
      description: "Our focus on building long-term relationships means that we prioritize our clients' interests, providing dedicated support and guidance throughout every phase of the transaction.",
      icon: <FaUserTie />
    }
  ];

  // 相关服务数据
  const relatedServices = [
    {
      title: "Acquisition Advisory",
      description: "Our acquisition services help investors identify and evaluate potential investments, conduct due diligence, and negotiate favorable terms.",
      image: image007
    },
    {
      title: "Disposition Strategy",
      description: "We develop and execute strategies to maximize the value of your assets when it's time to sell, ensuring optimal timing and positioning in the market.",
      image: image008
    },
    {
      title: "Financial Structuring",
      description: "Our experts provide creative financial solutions to optimize capital structure, minimize risk, and enhance returns on your commercial property investments.",
      image: image009
    }
  ];

  return (
    <div className="capital-transactions">
      {/* 高端视觉头部区域 */}
      <div className="capital-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="capital-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Capitalize on Commercial</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Commercial Property Capital Transaction</motion.h3>
          <motion.button 
            className="capital-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="capital-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Commercial Property Capital Transaction</h2>
          <div className="gold-separator"></div>
          <p>
            Whether you're looking to invest in high-value assets, restructure your portfolio, or secure financing for a new venture, THE ONE PRESTIGE is your trusted partner in making informed and strategic decisions. We pride ourselves on our client-centric approach, ensuring that each transaction is managed with the highest level of integrity, transparency, and efficiency.
          </p>
          <p>
            Our dedicated team of professionals is adept at handling all aspects of capital transactions, including acquisition, disposition, financing, and strategic advisory. We leverage our deep market insights and extensive network to deliver tailored strategies that maximize value and achieve optimal outcomes for our clients.
          </p>
          <p className="highlighted-text">
            "Partner with us to experience a seamless transaction process and unlock the full potential of your commercial property investments. Your success is our mission."
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Commercial Property Capital Transaction" />
        </motion.div>
      </div>

      {/* 能力部分 */}
      <div className="capabilities-section">
        <div className="capabilities-bg-pattern"></div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Capabilities
        </motion.h2>
        <div className="capabilities-container">
          {capabilities.map((capability, index) => (
            <motion.div 
              key={index}
              className="capability-row"
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ 
                delay: index * 0.2, 
                duration: 0.8, 
                ease: "easeOut" 
              }}
            >
              <div className="capability-icon-area">
                <div className="capability-icon-outer">
                  <div className="capability-icon-inner">
                    <div className="capability-icon">{capability.icon}</div>
                  </div>
                </div>
              </div>
              <div className="capability-connector"></div>
              <div className="capability-content-area">
                <h3 className="capability-title">{capability.title}</h3>
                <p className="capability-text">{capability.description}</p>
              </div>
              <div className="capability-number">{index + 1}</div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 图像展示墙 - 创新部分 */}
      <div className="image-showcase">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Portfolio Showcase
        </motion.h2>
        <div className="showcase-container">
          <div className="showcase-decor-line"></div>
          <div className="showcase-decor-line"></div>
          <div className="showcase-decor-circle"></div>
          <div className="showcase-decor-circle"></div>
          <div className="showcase-layout">
            <motion.div 
              className="showcase-item item-1"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7, ease: "easeOut" }}
            >
              <div className="showcase-image-wrapper">
                <img src={image002} alt="Premium Commercial Property" className="showcase-image" />
              </div>
              <div className="showcase-content">
                <span className="showcase-label">Featured</span>
                <h3 className="showcase-title">Premium Commercial Property</h3>
                <span className="showcase-separator"></span>
                <p className="showcase-description">Exceptional commercial spaces that set new standards in luxury and functionality.</p>
              </div>
              <div className="showcase-view">
                <i className="fas fa-eye"></i>
              </div>
            </motion.div>
            
            <motion.div 
              className="showcase-item item-2"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7, delay: 0.1, ease: "easeOut" }}
            >
              <div className="showcase-image-wrapper">
                <img src={image003} alt="Urban Development" className="showcase-image" />
              </div>
              <div className="showcase-content">
                <span className="showcase-label">Development</span>
                <h3 className="showcase-title">Urban Development</h3>
                <span className="showcase-separator"></span>
                <p className="showcase-description">Transforming urban landscapes with innovative design.</p>
              </div>
              <div className="showcase-view">
                <i className="fas fa-eye"></i>
              </div>
            </motion.div>
            
            <motion.div 
              className="showcase-item item-3"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7, delay: 0.2, ease: "easeOut" }}
            >
              <div className="showcase-image-wrapper">
                <img src={image004} alt="Sydney Landmarks" className="showcase-image" />
              </div>
              <div className="showcase-content">
                <span className="showcase-label">Iconic</span>
                <h3 className="showcase-title">Sydney Landmarks</h3>
                <span className="showcase-separator"></span>
                <p className="showcase-description">Prestigious properties in iconic locations.</p>
              </div>
              <div className="showcase-view">
                <i className="fas fa-eye"></i>
              </div>
            </motion.div>
            
            <motion.div 
              className="showcase-item item-4"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
            >
              <div className="showcase-image-wrapper">
                <img src={image005} alt="Modern Architecture" className="showcase-image" />
              </div>
              <div className="showcase-content">
                <span className="showcase-label">Architecture</span>
                <h3 className="showcase-title">Modern Architecture</h3>
                <span className="showcase-separator"></span>
                <p className="showcase-description">Cutting-edge design meeting functional excellence in commercial spaces.</p>
              </div>
              <div className="showcase-view">
                <i className="fas fa-eye"></i>
              </div>
            </motion.div>
            
            <motion.div 
              className="showcase-item item-5"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7, delay: 0.4, ease: "easeOut" }}
            >
              <div className="showcase-image-wrapper">
                <img src={image006} alt="Sydney City Night" className="showcase-image" />
              </div>
              <div className="showcase-content">
                <span className="showcase-label">City View</span>
                <h3 className="showcase-title">Sydney City Night</h3>
                <span className="showcase-separator"></span>
                <p className="showcase-description">Prime commercial locations with breathtaking city views.</p>
              </div>
              <div className="showcase-view">
                <i className="fas fa-eye"></i>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* 视觉分隔部分 */}
      <div className="visual-divider">
        <div className="parallax-image" style={{ backgroundImage: `url(${image002})` }}>
          <div className="overlay"></div>
          <div className="content-container">
            <motion.div
              className="divider-content"
              initial={{ opacity: 0, y: 20, x: -20 }}
              whileInView={{ opacity: 1, y: 0, x: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.8, 
                ease: "easeOut",
                delay: 0.2
              }}
            >
              <motion.h2
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Premier Capital Transaction Services in Sydney
              </motion.h2>
              <motion.div 
                className="gold-separator"
                initial={{ width: 0 }}
                whileInView={{ width: "80px" }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.8 }}
              ></motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1 }}
              >
                At THE ONE PRESTIGE, we understand that each property represents a significant investment. Our capital transaction services are designed to maximize the value of your commercial real estate assets through strategic planning and execution.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* 相关服务部分 */}
      <div className="services-section">
        <div className="services-bg-pattern"></div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Capital Transaction Related Services
        </motion.h2>
        <div className="services-grid">
          {relatedServices.map((service, index) => (
            <motion.div 
              key={index}
              className="service-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <div className="service-image">
                <img src={service.image} alt={service.title} />
              </div>
              <div className="service-content">
                <h3 className="service-title">{service.title}</h3>
                <p className="service-text">{service.description}</p>
                <button 
                  className="service-link" 
                  onClick={(e) => e.preventDefault()}
                >
                  Learn more <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 联系部分 */}
      <div className="contact-section">
        <div className="contact-overlay"></div>
        <div className="contact-pattern"></div>
        <motion.div 
          className="contact-content"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2>Ready to Maximize Your Investment?</h2>
          <p>
            Contact our team of capital transaction specialists today to discuss how we can help you achieve your commercial property investment goals.
          </p>
          <Link to="/contact">
            <motion.button 
              className="contact-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Contact Our Team
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default CapitalTransactions; 