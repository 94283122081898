import React, { useEffect } from 'react';
import '../styles/PremiumRentalManagement.css';
import image001 from '../assets/images/premium_rental_management/001.jpeg';
import image002 from '../assets/images/premium_rental_management/002.jpeg';
import image003 from '../assets/images/premium_rental_management/003.jpeg';
import { motion } from 'framer-motion';

const PremiumRentalManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textReveal = {
    hidden: { opacity: 0, y: 30 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.7,
        delay: custom * 0.2,
        ease: [0.42, 0, 0.58, 1]
      }
    })
  };

  // 市场标准服务项目列表
  const standardServices = [
    { id: 1, name: 'Property Advertising', icon: 'fas fa-ad', description: 'Strategic marketing of your property across premium platforms' },
    { id: 2, name: 'Tenant Screening', icon: 'fas fa-user-check', description: 'Rigorous vetting process to ensure high-quality tenants' },
    { id: 3, name: 'Property Inspections', icon: 'fas fa-clipboard-check', description: 'Regular detailed inspections with comprehensive reports' },
    { id: 4, name: 'Rent Collection', icon: 'fas fa-hand-holding-usd', description: 'Reliable and timely collection with instant owner notifications' },
    { id: 5, name: 'Rent Reviews', icon: 'fas fa-chart-line', description: 'Strategic market analysis for optimal rental pricing' },
    { id: 6, name: 'Lease Documentation', icon: 'fas fa-file-signature', description: 'Legally sound contracts tailored to your property needs' },
    { id: 7, name: 'Tenant Communication', icon: 'fas fa-comments', description: 'Responsive and professional tenant relationship management' },
    { id: 8, name: 'Financial Reporting', icon: 'fas fa-file-invoice-dollar', description: 'Detailed financial statements and performance reports' },
    { id: 9, name: 'Vacating Coordination', icon: 'fas fa-door-open', description: 'Seamless tenant transitions with minimal vacant periods' },
    { id: 10, name: 'Legal Compliance', icon: 'fas fa-balance-scale', description: 'Up-to-date adherence to all property laws and regulations' },
    { id: 11, name: 'Dispute Resolution', icon: 'fas fa-gavel', description: 'Professional mediation and swift resolution of issues' },
    { id: 12, name: 'Maintenance Coordination', icon: 'fas fa-tools', description: 'Prompt handling of all property maintenance requirements' },
  ];

  // 扩展服务项目
  const extendedServices = [
    {
      id: 1,
      title: "Dedicated Portfolio Manager",
      description: "Each property is assigned to a senior portfolio manager who oversees all aspects of the management process, ensuring a personalized approach to every client.",
      icon: "fas fa-user-tie"
    },
    {
      id: 2,
      title: "Premium Property Marketing",
      description: "High-quality professional photography, virtual tours, and premium placement on leading property portals to attract the highest caliber of tenants.",
      icon: "fas fa-camera"
    },
    {
      id: 3,
      title: "Concierge Maintenance",
      description: "24/7 maintenance response with pre-vetted premium contractors and detailed reporting on all maintenance activities.",
      icon: "fas fa-tools"
    },
    {
      id: 4,
      title: "Lifestyle Management",
      description: "Assistance with utility connections, furniture procurement, home styling, and other lifestyle services for tenants of premium properties.",
      icon: "fas fa-couch"
    },
    {
      id: 5,
      title: "Investment Advisory",
      description: "Market analysis, investment strategy, and portfolio expansion advice from our team of property investment specialists.",
      icon: "fas fa-chart-line"
    },
    {
      id: 6,
      title: "Global Connection Network",
      description: "Access to our network of international investors, expatriates, and corporate clients seeking premium rental properties.",
      icon: "fas fa-globe"
    }
  ];

  return (
    <div className="prm-premium-rental-management">
      {/* 豪华头部区域 */}
      <div className="prm-premium-hero-section">
        <div className="prm-designer-background">
          <div className="prm-gradient-overlay"></div>
          <div className="prm-pattern-overlay"></div>
          <div className="prm-animated-shapes">
            <div className="prm-shape prm-shape-1"></div>
            <div className="prm-shape prm-shape-2"></div>
            <div className="prm-shape prm-shape-3"></div>
          </div>
        </div>
        <motion.div 
          className="prm-premium-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 variants={textReveal} custom={1}>THE ONE PRESTIGE</motion.h1>
          <motion.h2 variants={textReveal} custom={2}>Premium Rental & Leasing Management</motion.h2>
          <motion.p variants={textReveal} custom={3}>
            Whatever the market standard offers, we offer more.
          </motion.p>
          <motion.button 
            className="prm-premium-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            Elevate Your Property Management
          </motion.button>
        </motion.div>
      </div>

      {/* 标准服务部分 - 重新设计 */}
      <div className="prm-standard-services-section">
        <div className="prm-services-background">
          <div className="prm-gradient-bg"></div>
          <div className="prm-pattern-bg"></div>
        </div>
        <div className="prm-section-container">
          <motion.h2 
            className="prm-section-title"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Market Standard Services
          </motion.h2>
          <motion.p 
            className="prm-section-description"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Here we list some major services a market standard residential leasing & rental management service should offer:
          </motion.p>
          
          <div className="prm-standard-services-container">
            {standardServices.map((service, index) => (
              <motion.div 
                key={service.id} 
                className="prm-service-hexagon"
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.05 }}
              >
                <div className="prm-hexagon-content">
                  <div className="prm-service-icon">
                    <i className={service.icon}></i>
                  </div>
                  <div className="prm-service-id">{service.id}</div>
                  <h3>{service.name}</h3>
                  <div className="prm-service-description">
                    <p>{service.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          
          <motion.div 
            className="prm-services-cta"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
          >
            <span>These are just the basics. Our premium services go far beyond.</span>
            <div className="prm-arrow-down">
              <i className="fas fa-chevron-down"></i>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 视觉分隔部分 */}
      <div className="prm-visual-divider">
        <div className="prm-parallax-image" style={{ backgroundImage: `url(${image003})` }}>
          <div className="prm-overlay"></div>
          <div className="prm-content-container">
            <motion.h2
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              The One Prestige
            </motion.h2>
            <motion.div 
              className="prm-gold-separator"
              initial={{ width: 0 }}
              whileInView={{ width: "120px" }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.3 }}
            ></motion.div>
            <motion.h3
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              extended services
            </motion.h3>
          </div>
        </div>
      </div>

      {/* 扩展服务部分 */}
      <div className="prm-extended-services-section">
        <div className="prm-section-container">
          <motion.h2 
            className="prm-section-title"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            What are our extended services?
          </motion.h2>
          <motion.p 
            className="prm-section-description"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Apart from the market standard Residential Leasing & Rental Management services, we offer a lot more. Here we list out some of our major extended services:
          </motion.p>
          
          <div className="prm-extended-services-container">
            <div className="prm-image-showcase">
              <div className="prm-image-stack">
                <motion.div 
                  className="prm-image prm-image-1"
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                >
                  <img src={image001} alt="Premium Property" />
                </motion.div>
                <motion.div 
                  className="prm-image prm-image-2"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3 }}
                >
                  <img src={image002} alt="Luxury Interior" />
                </motion.div>
              </div>
            </div>
            
            <div className="prm-extended-services-list">
              {extendedServices.map((service, index) => (
                <motion.div 
                  key={service.id} 
                  className="prm-extended-service-card"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="prm-service-icon">
                    <i className={service.icon}></i>
                  </div>
                  <div className="prm-service-content">
                    <h3>{service.title}</h3>
                    <div className="prm-service-separator"></div>
                    <p>{service.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* 联系部分 */}
      <div className="prm-premium-contact-section">
        <div className="prm-contact-overlay"></div>
        <motion.div 
          className="prm-premium-contact-content"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2>Experience Premium Property Management</h2>
          <div className="prm-contact-separator"></div>
          <p>Elevate your investment property with our distinguished management services</p>
          <div className="prm-premium-cta-buttons">
            <motion.button 
              className="prm-premium-primary-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Request Management Proposal
            </motion.button>
            <motion.button 
              className="prm-premium-secondary-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Speak with a Portfolio Manager
            </motion.button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PremiumRentalManagement; 