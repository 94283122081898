import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/OurCompany.css';
import image001 from '../assets/images/our_company/001.jpeg';
import image002 from '../assets/images/our_company/002.jpeg';
import image003 from '../assets/images/our_company/003.jpeg';
import image004 from '../assets/images/our_company/004.jpeg';

const OurCompany = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="our-company-page">
      <div className="hero-section">
        <div className="hero-content" data-aos="fade-up">
          <h1>THE ONE PRESTIGE</h1>
          <h2>Where Exceptional Real Estate Meets Unrivaled Service</h2>
          <div className="hero-quote">
            <p>"We redefine luxury in real estate. Our expert team delivers unparalleled service and exclusive access to the most coveted properties. With a commitment to excellence and an eye for sophistication, we turn your real estate dreams into reality. Experience the pinnacle of prestige and elevate your lifestyle with us."</p>
            <span>— Message from the Directors</span>
          </div>
        </div>
        <div className="scroll-indicator">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
        </div>
      </div>

      <div className="vision-section">
        <div className="vision-content" data-aos="fade-up">
          <p className="elegant-text">
            Recognizing the needs of property stakeholders, One Prestige has successfully built a business that addresses the needs of all parties along the property supply chain and across all property classes (Commercial, Retail, Industrial). The One Prestige offers a personalized service, leading to strong, lengthy relationships with our clients.
          </p>
        </div>
      </div>

      <div className="image-gallery">
        <div className="gallery-grid">
          <div className="gallery-item" data-aos="fade-right" data-aos-duration="800">
            <img src={image001} alt="Premium Properties" />
            <div className="overlay">
              <h3>Premium Properties</h3>
            </div>
          </div>
          <div className="gallery-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="100">
            <img src={image002} alt="Personalized Service" />
            <div className="overlay">
              <h3>Personalized Service</h3>
            </div>
          </div>
          <div className="gallery-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="200">
            <img src={image003} alt="Expert Guidance" />
            <div className="overlay">
              <h3>Expert Guidance</h3>
            </div>
          </div>
          <div className="gallery-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
            <img src={image004} alt="Luxury Living" />
            <div className="overlay">
              <h3>Luxury Living</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="about-section">
        <div className="about-content">
          <div className="text-column" data-aos="fade-right">
            <h2>Our Commitment</h2>
            <p>At THE ONE PRESTIGE, we're more than just a team of agents – we're your dedicated partners in navigating the dynamic world of real estate. With a passion for excellence and a commitment to unparalleled service, we're here to redefine your real estate experience.</p>
            <p>What sets us apart? It's our unwavering dedication to our clients. We understand that buying, selling, or renting a property is not just a transaction; it's a milestone in your life journey. That's why we go above and beyond to ensure that every step of the process is smooth, stress-free, and tailored to your unique needs.</p>
          </div>
          <div className="text-column" data-aos="fade-left">
            <h2>Our Expertise</h2>
            <p>Specializing in premium rental management services and property sales, we leverage our expertise, market knowledge, and innovative strategies to deliver outstanding results. Whether you're a landlord seeking top-tier management for your investment property or a buyer/seller looking to achieve your real estate goals, we're here to make it happen.</p>
            <p>But it's not just about transactions – it's about building lasting relationships. We take the time to listen, understand, and prioritize your goals, because your success is our success. With integrity, professionalism, and a genuine passion for what we do, we strive to exceed your expectations every step of the way.</p>
          </div>
        </div>
      </div>

      <div 
        className="cta-section" 
        data-aos="fade-up"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 18, 51, 0.9), rgba(0, 18, 51, 0.9)), url(${image001})`
        }}
      >
        <div className="cta-content">
          <h2>Welcome to THE ONE PRESTIGE</h2>
          <p>Welcome to the future of real estate in Barangaroo, Circular Quay and Sydney CBD</p>
          <Link to="/contact" className="cta-button">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default OurCompany; 