import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaChartLine, FaBuilding, FaSearchDollar, FaFileAlt, FaLightbulb } from 'react-icons/fa';
import '../styles/CapitalAdvisory.css';

// 导入图片
import image001 from '../assets/images/finance/001.jpeg';
import image002 from '../assets/images/finance/002.jpeg';

const CapitalAdvisory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 核心服务数据
  const coreServices = [
    {
      title: "Strategic Investment Guidance",
      description: "We provide expert advice on identifying and evaluating real estate investment opportunities that align with your business goals. Whether you're considering property acquisitions, developments, or portfolio diversification, we help you make decisions that maximize potential returns.",
      icon: <FaChartLine />
    },
    {
      title: "Capital Structuring Advice",
      description: "Our team offers strategic insights on optimizing your capital structure to support your real estate ventures. We assist in exploring various funding options, including partnerships and joint ventures, to ensure your projects are financially viable and aligned with your long-term objectives.",
      icon: <FaBuilding />
    },
    {
      title: "Market Insights and Analysis",
      description: "We offer comprehensive market analysis and forecasting to help you stay ahead of industry trends. Our insights assist you in making well-timed property decisions, whether it involves buying, selling, or developing real estate assets.",
      icon: <FaSearchDollar />
    },
    {
      title: "Asset Optimization",
      description: "Through our advisory services, we help you enhance the performance of your existing property portfolio. We provide recommendations on improving asset management practices, increasing cash flow, and optimizing property value.",
      icon: <FaFileAlt />
    }
  ];

  return (
    <div className="capital-advisory">
      {/* 高端视觉头部区域 */}
      <div className="advisory-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="advisory-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Capital Advisory</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Strategic Property Investment Solutions</motion.h3>
          <motion.button 
            className="advisory-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="advisory-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Capital Advisory Services</h2>
          <div className="gold-separator"></div>
          <p>
            We specialize in offering strategic property-related advisory services designed to enhance the value and performance of your real estate investments. While we do not provide financial services, our experienced team offers expert guidance to help you navigate complex property transactions and make informed decisions.
          </p>
          <p>
            The One Prestige Capital Advisory delivers debt and equity funding solutions to match the strategic needs of our clients.
          </p>
          <p>
            With an extensive track record across asset classes and geographies, our team will work with you to advise on underwriting, structuring, approach to market and execution to deliver the most competitive funding solution for your investment via our global network of debt and equity relationships.
          </p>
          <p className="highlighted-text">
            "Our team is backed by our global network of more than 8 offices globally, ensuring delivery of market leading expertise and access to capital markets."
          </p>
          <p>
            We work closely with our Commercial Sales and Investment business lines we provide market leading transactional insight and research for each investment which is utilised.
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Capital Advisory Services" />
        </motion.div>
      </div>

      {/* 核心服务部分 */}
      <div className="core-services-section">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Core Services
        </motion.h2>
        <div className="services-container">
          {coreServices.map((service, index) => (
            <motion.div 
              key={index}
              className="service-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7, 
                delay: index * 0.1,
                ease: "easeOut" 
              }}
              whileHover={{ 
                y: -10,
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                transition: { duration: 0.3 } 
              }}
            >
              <div className="service-icon">
                {service.icon}
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 结论部分 */}
      <div className="conclusion-section">
        <div className="conclusion-background">
          <img src={image002} alt="Capital Advisory" />
          <div className="overlay"></div>
        </div>
        <motion.div 
          className="conclusion-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="conclusion-icon">
            <FaLightbulb />
          </div>
          <h2>The One Prestige Advantage</h2>
          <p>
            At The One Prestige, we provide valuable property advisory services that support your real estate ambitions. Our goal is to equip you with the insights and strategies needed to achieve success in the dynamic property market.
          </p>
          <Link to="/contact" className="contact-link">
            Contact Our Advisory Team
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default CapitalAdvisory; 