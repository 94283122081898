import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaHome, FaTools, FaTree, FaIndustry, FaBuilding, FaCity } from 'react-icons/fa';
import '../styles/FundingSolutions.css';

// 导入图片
import image001 from '../assets/images/finance/001.jpeg';
import image002 from '../assets/images/finance/002.jpeg';

const FundingSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  // 解决方案数据
  const fundingSolutions = [
    {
      title: "Home Loan",
      description: "At The One Prestige, we simplify the journey to homeownership with our comprehensive home loan services. Our dedicated team of financial experts is committed to finding the right loan solution tailored to your unique needs and goals.",
      icon: <FaHome />
    },
    {
      title: "Construction and Renovation",
      description: "Turning your vision into reality is our specialty at The One Prestige. Our construction loan services are designed to provide the financial support you need to build or renovate your dream home with confidence.",
      icon: <FaTools />
    },
    {
      title: "Rural Loans",
      description: "Whether you're looking to expand your farm, purchase equipment, or develop rural property, our rural loan services offer flexible financing options to help you achieve your goals.",
      icon: <FaTree />
    },
    {
      title: "Industrial Loans",
      description: "For businesses operating in the industrial sector, our industrial loan services offer the capital needed for equipment purchases, facility upgrades, or expansion projects.",
      icon: <FaIndustry />
    },
    {
      title: "Commercial Real Estate Financing",
      description: "Unlock the potential of your property investments with The One Prestige's tailored commercial real estate financing solutions. Whether you're acquiring, developing, refinancing, or investing in commercial properties.",
      icon: <FaBuilding />
    },
    {
      title: "Development Funding Services",
      description: "Transform your development projects with The One Prestige's expert funding solutions. We provide tailored financial support for every phase of your project, from land acquisition and pre-construction to construction and refinancing.",
      icon: <FaCity />
    }
  ];

  return (
    <div className="funding-solutions">
      {/* 高端视觉头部区域 */}
      <div className="funding-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="funding-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Finance Solutions</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Prestige in Finance, Excellence in Service</motion.h3>
          <motion.button 
            className="funding-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Solutions
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="funding-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Crafting Financial Success with Prestige and Precision</h2>
          <div className="gold-separator"></div>
          <p>
            At The One Prestige, we understand that your financial well-being is a cornerstone of a fulfilling life. As a leading provider of personalized financial services, our mission is to empower you with tailored solutions that align with your unique goals and aspirations.
          </p>
          <p>
            Our team of seasoned experts brings a wealth of knowledge and a commitment to excellence, ensuring that every strategy we develop is crafted with precision and care. Whether you're planning for retirement, managing investments, or seeking advice on estate planning, we offer a comprehensive suite of services designed to optimize your financial health.
          </p>
          <p className="highlighted-text">
            "Finance Solutions and Advisories for you and your business"
          </p>
          <p>
            From home loans to commercial real estate financing, our diverse range of financial solutions is designed to meet your specific needs. We work closely with you to understand your objectives and provide expert guidance every step of the way.
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Finance Solutions" />
        </motion.div>
      </div>

      {/* 解决方案部分 */}
      <div className="solutions-section">
        <div className="solutions-background">
          <div className="bg-pattern"></div>
        </div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Finance Solutions
        </motion.h2>
        <div className="solutions-container">
          {fundingSolutions.map((solution, index) => (
            <motion.div 
              key={index}
              className="solution-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7, 
                delay: index * 0.1,
                ease: "easeOut" 
              }}
              whileHover={{ 
                y: -10,
                boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                transition: { duration: 0.3 } 
              }}
            >
              <div className="solution-icon">
                {solution.icon}
              </div>
              <h3>{solution.title}</h3>
              <p>{solution.description}</p>
              <div className="card-accent"></div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* 服务流程部分 */}
      <div className="funding-process-section">
        <div className="process-background">
          <img src={image002} alt="Finance Process" />
          <div className="overlay"></div>
        </div>
        <motion.div 
          className="process-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2>Our Financial Service Process</h2>
          <div className="process-steps">
            <div className="process-step">
              <span className="step-number">1</span>
              <div className="step-content">
                <h3>Initial Consultation</h3>
                <p>We begin with a thorough understanding of your financial goals, timeline, and specific requirements to create a personalized approach.</p>
              </div>
            </div>
            <div className="process-step">
              <span className="step-number">2</span>
              <div className="step-content">
                <h3>Solution Development</h3>
                <p>Our experts create a customized financial strategy that aligns with your objectives and optimizes your financial position.</p>
              </div>
            </div>
            <div className="process-step">
              <span className="step-number">3</span>
              <div className="step-content">
                <h3>Implementation</h3>
                <p>We efficiently implement your financial solution, handling all necessary paperwork and coordination to ensure a smooth process.</p>
              </div>
            </div>
            <div className="process-step">
              <span className="step-number">4</span>
              <div className="step-content">
                <h3>Ongoing Support</h3>
                <p>Our relationship continues with regular reviews and adjustments to your financial strategy as your needs and market conditions evolve.</p>
              </div>
            </div>
          </div>
          <Link to="/contact" className="contact-link">
            Discuss Your Financial Needs
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default FundingSolutions; 