import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaBuilding, FaTools, FaChartPie, FaUserTie, FaLeaf } from 'react-icons/fa';
import '../styles/AssetManagement.css';

// 导入图片
import image001 from '../assets/images/asset_management/001.jpeg';
import image002 from '../assets/images/asset_management/002.jpeg';

const AssetManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 文本动画变量
  const textReveal = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.6,
        ease: "easeOut"
      }
    })
  };

  // 内容动画变量
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="asset-management">
      {/* 高端视觉头部区域 */}
      <div className="asset-hero-section">
        <div className="designer-background">
          <div className="gradient-overlay"></div>
          <div className="pattern-overlay"></div>
          <div className="light-effect"></div>
          <div className="line-decoration"></div>
          <div className="animated-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
          <div className="hero-particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
        <motion.div 
          className="asset-hero-content"
          initial="hidden"
          animate="visible"
        >
          <motion.h1 
            variants={textReveal} 
            custom={1}
            whileHover={{ letterSpacing: "8px", transition: { duration: 0.3 } }}
          >THE ONE PRESTIGE</motion.h1>
          <motion.h2 
            variants={textReveal} 
            custom={2}
            whileHover={{ scale: 1.02, transition: { duration: 0.3 } }}
          >Assets Managed, Growth Delivered</motion.h2>
          <motion.h3 
            variants={textReveal} 
            custom={3}
          >Meet Your Asset Management Experts</motion.h3>
          <motion.button 
            className="asset-cta-button"
            variants={textReveal}
            custom={4}
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Our Services
          </motion.button>
        </motion.div>
      </div>

      {/* 介绍部分 */}
      <div className="asset-introduction">
        <div className="subtle-pattern"></div>
        <motion.div 
          className="intro-content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <h2>Asset Management Solutions</h2>
          <div className="gold-separator"></div>
          <p>
            We understand that no two assets are the same – no matter your asset class, investment strategy or environmental, social and governance goals, we've got an adaptable service for you. We work with clients to develop and implement asset and portfolio management solutions that enhance value, increase efficiency, boost tenant retention and provide integrated sustainability solutions.
          </p>
          <p className="highlighted-text">
            "Asset management is the strategic optimization of assets to maximize returns, mitigate risks, and achieve long-term financial goals."
          </p>
          <p>
            Our team excels in building diversified portfolios that align with client objectives and risk tolerance, optimizing returns while mitigating risk. Our wide range of property management services include:
          </p>
        </motion.div>
        <motion.div 
          className="intro-image"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeIn}
        >
          <img src={image001} alt="Asset Management Services" />
        </motion.div>
      </div>

      {/* 服务部分 */}
      <div className="services-section">
        <div className="services-bg-pattern"></div>
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Our Asset Management Services
        </motion.h2>
        <div className="services-container">
          {/* 房产管理服务 */}
          <motion.div 
            className="service-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            <div className="service-header">
              <span className="service-icon"><FaBuilding /></span>
              <h3>Property Management</h3>
            </div>
            <div className="service-content">
              <p>
                Our approach to Property Management begins with understanding your asset and your strategic objectives, so we can work with our Facilities and Financial Management colleagues to accelerate the success of your investment.
              </p>
              <ul className="service-features">
                <li>
                  <strong>Increased tenant retention</strong> – carefully listening, planning forward and never complacent, we are dedicated to creating a customer experience like no other so that we provide the best outcomes for our clients and their tenants.
                </li>
                <li>
                  <strong>Increased revenue</strong> – we deliver enhanced asset value and greater cash flow through increased tenant satisfaction and operational efficiencies.
                </li>
                <li>
                  <strong>Reduced costs</strong> – we immediately conduct a property performance audit program to deliver greater operational efficiencies, lower outgoings and increased asset value.
                </li>
              </ul>
            </div>
          </motion.div>

          {/* 设施管理服务 */}
          <motion.div 
            className="service-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="service-header">
              <span className="service-icon"><FaTools /></span>
              <h3>Facilities Management</h3>
            </div>
            <div className="service-content">
              <p>
                Working closely with our clients, our teams create strategies to most effectively organise the operation and performance of property assets. We specialise in providing operational support such as building management, facility management, occupational health & safety management, compliance management, in-house security and more.
              </p>
            </div>
          </motion.div>

          {/* 财务管理服务 */}
          <motion.div 
            className="service-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <div className="service-header">
              <span className="service-icon"><FaChartPie /></span>
              <h3>Financial Management</h3>
            </div>
            <div className="service-content">
              <p>
                Our specialist teams provide financial services direct to clients, allowing property owners and managers to make informed decisions, and thereby maximise the returns on their properties. We are able to assist with financial reporting, accounts payable, budgeting and forecasting, lease maintenance, balance sheet reporting and much more.
              </p>
            </div>
          </motion.div>

          {/* 投资组合管理服务 */}
          <motion.div 
            className="service-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div className="service-header">
              <span className="service-icon"><FaUserTie /></span>
              <h3>Portfolio Management</h3>
            </div>
            <div className="service-content">
              <p>
                Our team excels in building diversified portfolios that align with client objectives and risk tolerance, optimizing returns while mitigating risk.
              </p>
              <ul className="service-features">
                <li>
                  We apply advanced analytics and data-driven insights to identify investment opportunities and implement strategic asset allocation to enhance portfolio performance.
                </li>
                <li>
                  Our robust risk management framework safeguards client investments through rigorous monitoring, stress testing, and scenario analysis.
                </li>
                <li>
                  We maintain portfolio alignment with investment goals by implementing timely rebalancing strategies based on market conditions and client objectives.
                </li>
              </ul>
            </div>
          </motion.div>

          {/* 可持续发展咨询 */}
          <motion.div 
            className="service-item"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <div className="service-header">
              <span className="service-icon"><FaLeaf /></span>
              <h3>Sustainability Advisory</h3>
            </div>
            <div className="service-content">
              <p>
                Our sustainable asset management is underwritten by 5-star Green Star and ABGR ratings, and our real estate strategy is consistently forward thinking in its approach to client needs. We have vast experience in all aspects of the sustainable built environment, across a broad band of market sectors, including offices, hotels and shopping centres.
              </p>
            </div>
          </motion.div>
        </div>
      </div>

      {/* 视觉分隔部分 */}
      <div className="visual-divider">
        <div className="parallax-image" style={{ backgroundImage: `url(${image002})` }}>
          <div className="overlay"></div>
          <div className="content-container">
            <motion.div
              className="divider-content"
              initial={{ opacity: 0, y: 20, x: -20 }}
              whileInView={{ opacity: 1, y: 0, x: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.8, 
                ease: "easeOut",
                delay: 0.2
              }}
            >
              <motion.h2
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                Sydney's Premier Commercial Leasing Specialists
              </motion.h2>
              <motion.div 
                className="gold-separator"
                initial={{ width: 0 }}
                whileInView={{ width: "80px" }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.8 }}
              ></motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1 }}
              >
                The One Prestige is a commercial leasing specialist based in the heart of Sydney CBD. We offer comprehensive property management services for landlords and efficient space solutions for tenants. Our deep understanding of the Sydney market enables us to deliver tailored leasing strategies and exceptional customer service.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* 联系部分 */}
      <div className="contact-section">
        <div className="contact-overlay"></div>
        <div className="contact-pattern"></div>
        <motion.div 
          className="contact-content"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2>Ready to Optimize Your Asset Management?</h2>
          <p>
            "A successful commercial leasing service should focus on delivering value to both landlords and tenants, building strong relationships with both parties and achieve long-term success."
          </p>
          <Link to="/contact">
            <motion.button 
              className="contact-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
            >
              Contact Our Team
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default AssetManagement; 