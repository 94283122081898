import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const handleDropdownEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null);
  };
  
  return (
    <nav className={`navbar ${scrolled || !isHomePage ? 'scrolled' : ''}`}>
      <div className="container">
        <div className="navbar-brand">
          <Link to="/">
            <img src="/images/Logo.png" alt="The One Prestige" className="logo" />
          </Link>
        </div>
        
        <div className="navbar-menu">
          <ul className="nav-links">
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(0)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                Residential
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 0 ? 'show' : ''}`}>
                <Link to="/residential/residential-sell">Residential Sell</Link>
                <Link to="/residential/premium-rental-management">Premium Management</Link>
                {/* <Link to="/residential/premium-leasing">Premium Leasing</Link> */}
                <Link to="/residential/property-appraisal">Property Appraisal</Link>
                {/* <Link to="/residential/residential-valuation">Residential Valuation</Link> */}
              </div>
            </li>
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(1)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                Commercial
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 1 ? 'show' : ''}`}>
                <Link to="/commercial/commercial-lease">Commercial Lease</Link>
                <Link to="/commercial/asset-management">Asset Management</Link>
                <Link to="/commercial/capital-transaction">Capital Transaction</Link>
                <Link to="/commercial/asia-pacific-market">Asia Pacific Market</Link>
                <Link to="/commercial/Tenant-Representative">Tenant Representative</Link>
              </div>
            </li>
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(2)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                Project
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 2 ? 'show' : ''}`}>
                <Link to="/project/residential-project-marketing">Residential Project Marketing</Link>
                <Link to="/project/residential-project-management">Residential Project Management</Link>
              </div>
            </li>
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(3)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                Financial Services
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 3 ? 'show' : ''}`}>
                <Link to="/financial-services/capital-advisory">Capital Advisory</Link>
                <Link to="/financial-services/development-feasibility">Development Feasibility</Link>
                <Link to="/financial-services/development-management">Development Management</Link>
                <Link to="/financial-services/funding-solutions">Funding Solutions</Link>
              </div>
            </li>
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(4)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                The One Club
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 4 ? 'show' : ''}`}>
                <Link to="/the-one-club/membership">Membership</Link>
                <Link to="/the-one-club/events">Events</Link>
                <Link to="/the-one-club/benefits">Benefits</Link>
              </div>
            </li>
            <li 
              className="nav-item dropdown"
              onMouseEnter={() => handleDropdownEnter(5)}
              onMouseLeave={handleDropdownLeave}
            >
              <span className="nav-link">
                About Us
                <span className="dropdown-arrow">▼</span>
              </span>
              <div className={`dropdown-content ${activeDropdown === 5 ? 'show' : ''}`}>
                <Link to="/about-us/company">Company</Link>
                <Link to="/about-us/team">Team</Link>
                <Link to="/about-us/contact-us">Contact Us</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 