import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar.jsx';
import HomePage from './pages/HomePage.jsx';
import OurCompany from './pages/OurCompany.jsx';
import Team from './pages/Team.jsx';
import Footer from './components/Footer.jsx';
import ResidentialSell from './pages/ResidentialSell.jsx';
import PremiumRentalManagement from './pages/PremiumRentalManagement.jsx';
import CommercialLease from './pages/CommercialLease.jsx';
import ProjectMarketing from './pages/AssetManagement.jsx';
import CapitalTransaction from './pages/CapitalTransactions.jsx';
import AsiaPacificMarkets from './pages/AsiaPacificMarkets.jsx';
import TenantRepresentative from './pages/TenantRepresentative.jsx';
import ResidentialProjectMarketing from './pages/ResidentialProjectMarketing.jsx';
import ResidentialProjectManagement from './pages/ResidentialProjectManagement.jsx';
import CapitalAdvisory from './pages/CapitalAdvisory.jsx';
import DevelopmentFeasibility from './pages/DevelopmentFeasibility.jsx';
import DevelopmentManagement from './pages/DevelopmentManagement.jsx';
import FundingSolutions from './pages/FundingSolutions.jsx';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us/company" element={<OurCompany />} />
            <Route path="/about-us/team" element={<Team />} />
            <Route path="/residential/residential-sell" element={<ResidentialSell />} />
            <Route path="/residential/premium-rental-management" element={<PremiumRentalManagement />} />
            <Route path="/commercial/Commercial-Lease" element={<CommercialLease />} />
            <Route path="/commercial/asset-management" element={<ProjectMarketing />} />
            <Route path="/commercial/capital-transaction" element={<CapitalTransaction />} />
            <Route path="/commercial/asia-pacific-market" element={<AsiaPacificMarkets />} />
            <Route path="/commercial/tenant-representative" element={<TenantRepresentative />} />
            <Route path="/project/residential-project-marketing" element={<ResidentialProjectMarketing />} />
            <Route path="/project/residential-project-management" element={<ResidentialProjectManagement />} />
            <Route path="/financial-services/capital-advisory" element={<CapitalAdvisory />} />
            <Route path="/financial-services/development-feasibility" element={<DevelopmentFeasibility />} />
            <Route path="/financial-services/development-management" element={<DevelopmentManagement />} />
            <Route path="/financial-services/funding-solutions" element={<FundingSolutions />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
